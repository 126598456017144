exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blog/_post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-dev-policies-js": () => import("./../../../src/pages/dev/policies.js" /* webpackChunkName: "component---src-pages-dev-policies-js" */),
  "component---src-pages-get-a-quote-index-js": () => import("./../../../src/pages/get-a-quote/index.js" /* webpackChunkName: "component---src-pages-get-a-quote-index-js" */),
  "component---src-pages-get-a-quote-success-js": () => import("./../../../src/pages/get-a-quote/success.js" /* webpackChunkName: "component---src-pages-get-a-quote-success-js" */),
  "component---src-pages-homepage-partners-companies-index-js": () => import("./../../../src/pages/_homepage/_partnersCompanies/index.js" /* webpackChunkName: "component---src-pages-homepage-partners-companies-index-js" */),
  "component---src-pages-homepage-portfolio-showcases-ai-js": () => import("./../../../src/pages/_homepage/_portfolio/_showcases/ai.js" /* webpackChunkName: "component---src-pages-homepage-portfolio-showcases-ai-js" */),
  "component---src-pages-homepage-portfolio-showcases-apps-js": () => import("./../../../src/pages/_homepage/_portfolio/_showcases/apps.js" /* webpackChunkName: "component---src-pages-homepage-portfolio-showcases-apps-js" */),
  "component---src-pages-homepage-portfolio-showcases-default-js": () => import("./../../../src/pages/_homepage/_portfolio/_showcases/default.js" /* webpackChunkName: "component---src-pages-homepage-portfolio-showcases-default-js" */),
  "component---src-pages-homepage-portfolio-showcases-next-gen-js": () => import("./../../../src/pages/_homepage/_portfolio/_showcases/next-gen.js" /* webpackChunkName: "component---src-pages-homepage-portfolio-showcases-next-gen-js" */),
  "component---src-pages-homepage-portfolio-showcases-web-js": () => import("./../../../src/pages/_homepage/_portfolio/_showcases/web.js" /* webpackChunkName: "component---src-pages-homepage-portfolio-showcases-web-js" */),
  "component---src-pages-homepage-team-index-js": () => import("./../../../src/pages/_homepage/_team/index.js" /* webpackChunkName: "component---src-pages-homepage-team-index-js" */),
  "component---src-pages-homepage-testimonial-slider-index-js": () => import("./../../../src/pages/_homepage/_testimonialSlider/index.js" /* webpackChunkName: "component---src-pages-homepage-testimonial-slider-index-js" */),
  "component---src-pages-homepage-testimonials-index-js": () => import("./../../../src/pages/_homepage/_testimonials/index.js" /* webpackChunkName: "component---src-pages-homepage-testimonials-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lost-found-index-js": () => import("./../../../src/pages/lost_found/index.js" /* webpackChunkName: "component---src-pages-lost-found-index-js" */),
  "component---src-pages-manager-index-js": () => import("./../../../src/pages/manager/index.js" /* webpackChunkName: "component---src-pages-manager-index-js" */),
  "component---src-pages-preferences-index-js": () => import("./../../../src/pages/preferences/index.js" /* webpackChunkName: "component---src-pages-preferences-index-js" */),
  "component---src-pages-services-ai-index-js": () => import("./../../../src/pages/services/ai/index.js" /* webpackChunkName: "component---src-pages-services-ai-index-js" */),
  "component---src-pages-services-mobile-index-js": () => import("./../../../src/pages/services/mobile/index.js" /* webpackChunkName: "component---src-pages-services-mobile-index-js" */),
  "component---src-pages-services-next-gen-index-js": () => import("./../../../src/pages/services/next-gen/index.js" /* webpackChunkName: "component---src-pages-services-next-gen-index-js" */),
  "component---src-pages-services-web-index-js": () => import("./../../../src/pages/services/web/index.js" /* webpackChunkName: "component---src-pages-services-web-index-js" */),
  "component---src-pages-terms-licence-js": () => import("./../../../src/pages/terms/licence.js" /* webpackChunkName: "component---src-pages-terms-licence-js" */),
  "component---src-pages-terms-website-js": () => import("./../../../src/pages/terms/website.js" /* webpackChunkName: "component---src-pages-terms-website-js" */)
}

