/* eslint react/no-unknown-property: 'off' */
import { React, View, onUpdate, useRef, useState } from 'lib'
import { Theme } from 'app'

const BackgroundVideo = (props) => {
  const { visible, src } = props
  const video = useRef(null)
  const [isReady, setReady] = useState(false)
  const { height } = Theme.hooks.size()

  onUpdate(() => {
    if (video.current) {
      if (visible) {
        video.current.muted = true
        const playPromise = video.current.play()
        if (playPromise !== undefined) {
          playPromise.then(() => {
            setReady(true)
          }).catch(error => {
            setReady(false)
            warn({ error })
          })
        }
      } else if (!visible && isReady) {
        video.current.pause()
      }
    }
  }, [visible])

  return (
    <View style={[styles.wrapper, { height: height || '100vh' }]}>
      <video
        ref={video}
        loop={'loop'}
        muted={true}
        playsInline={true}
        autoPlay={true}
        style={{ ...styles.video, height: height*1.1 }}
        src={src}
      >
          Your browser does not support background videos
      </video>
      <View style={[styles.overlay, styles.opacity, { height }]}/>

      <View style={[styles.fixedGradient, { height: height*0.25 }]}></View>

    </View>
  )
}

export default BackgroundVideo

const styles = {
  wrapper: {
    ...Theme.center,
    width: '100vw',
    position: 'absolute',
    overflow: 'hidden',
  },
  video: {
    overflow: 'hidden',
    width: '100vw',
    backgroundColor: '#555',
    objectFit: 'cover',
  },
  'video::-webkit-media-controls': {
    display: 'none',
  },
  overlay: {
    width: '100vw',
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  opacity: {
    opacity: 0.75,
    backgroundColor: 'black',
  },
  vignette: {
    background: 'radial-gradient(transparent, black)',
    boxShadow: 'inset 0 0 400px black',
  },
  scrollDownWrapper: {
    display: 'absolute',
    bottom: 0,
    left: 'auto',
    right: 'auto',
    opacity: 0.8,
    alignItems: 'center',

    justifyContent: 'center',
  },
  fixedGradient: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    flex: 1,
    background: 'linear-gradient(180deg, transparent, black)',
  },
}

