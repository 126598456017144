import { React, View, Text, Button, Link, useRedux, Touchable, useState, onMount } from 'lib'
import { Theme } from 'app'
import { Session } from 'actions'

function CookieConsent() {
  const cookiesAccepted = useRedux('Session.cookiesAccepted')
  const [show, setShow] = useState(false)

  const hasAccepted = cookiesAccepted == 'true'

  onMount(() => {
    if (!hasAccepted) {
      setTimeout(() => setShow(true), 3000)
    }
  })

  const accept = () => {
    Session.acceptCookies()
  }

  if (hasAccepted || !show) return null

  return (
    <View style={styles.wrapper}>
      <Touchable gaLabel={'CookieConsent'} gaAction={'Dialog box'} variant={'plain'} style={styles.innerWrapper}>
        <Text variant={`p2 offwhite inline`} style={styles.noticeText}>
          We use cookies to deliver you a better experience.<br/>By using our website you agree to our <Link openNewTab gaLabel={'CookieConsent'} variant={'inline inherit'} text={'terms of use'} to='/terms/website'/>.
        </Text>
        <View style={styles.acceptButtonWrapper}>
          <Button gaLabel={'CookieConsent'} gaAction={'Accept button'} variant={'outlineOffwhite small fullWidth round paddingHorizontal:2'} onPress={accept} text={'Got it'}/>
        </View>
      </Touchable>
    </View>
  )
}

const styles = {
  wrapper: {
    ...Theme.center,
    position: 'fixed',
    bottom: Theme.spacing(6),
    left: Theme.spacing(6),
    zIndex: 999,
    maxWidth: '55vw',
  },
  innerWrapper: {
    padding: Theme.spacing(1.5),
    borderRadius: Theme.values.borderRadius.small,
    backgroundColor: '#aaaaaa22',
    ...Theme.blur,
    display: 'flex',
    [Theme.media.down('mid')]: {
      flexDirection: 'column',
    },
  },
  noticeText: {
    '& a': {
      '&:hover': {
        textDecorationStyle: 'solid',
        textDecorationColor: Theme.colors.offwhite,
        textDecorationLine: 'underline',
      },
    },
  },
  acceptButtonWrapper: {
    marginLeft: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      marginLeft: 0,
      marginTop: Theme.spacing(1),
    },
  },
}

export default CookieConsent
