import { React, View, useRef, useState, useEffect, useMount, useUnmount } from 'lib'


function Fade(props) {
  const { children, variant, delay, ready, ...otherProps } = props
  const [show, setShow] = useState(false)
  const timer = useRef(null)


  useMount(() => {
    if (ready && !show) startTimer()
  })

  useUnmount(() => {
    clearTimer()
  })

  useEffect(() => {
    if (ready && !show) {
      startTimer()
    } else if (!ready) {
      clearTimer()
    }
  }, [ready])

  const startTimer = () => {
    if (timer.current) clearTimer()
    timer.current = setTimeout(() => setShow(true), delay)
  }

  const clearTimer = () => {
    clearTimeout(timer.current)
  }

  let styles = {}
  switch (variant) {
    case 'opacity':
      styles = opacityStyles
      break
    case 'scale':
      styles = scaleStyles
      break
    default:
      styles = defaultStyles
      break
  }

  return (
    <View style={[styles.hide, show && styles.show]} {...otherProps}>
      {children}
    </View>
  )
}

const defaultStyles = {
  hide: {
    transform: `translate3d(0, 80px, 0)`,
    opacity: 0,
    transition: '2000ms',
  },
  show: {
    transform: `translate3d(0, 0, 0)`,
    opacity: 1,
    transition: '2000ms',
  },
}

const opacityStyles = {
  hide: {
    opacity: 0,
    transition: '1000ms',
  },
  show: {
    opacity: 1,
    transition: '1000ms',
  },
}

const scaleStyles = {
  hide: {
    transform: `scale(0.6)`,
    opacity: 0,
    transition: '5000ms',
  },
  show: {
    transform: `scale(1)`,
    opacity: 1,
    transition: '5000ms',
  },
}

export default Fade
