import { View, Text, CenterWrapper, React, Image, Link, Touchable, AnalyticsView } from 'lib'
import { Settings } from 'app'
import moment from 'moment'
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa'

import styles from './styles'

const linksA = [
  { text: 'HOME', to: '/#' },
  { text: 'SERVICES', to: '/#services' },
  { text: 'PORTFOLIO', to: '/#portfolio' },
  { text: 'PRICING', to: '/#pricing' },
]

const linksB = [
  { text: 'ABOUT', to: '/about' },
  { text: 'BLOG', to: '/blog' },
  { text: 'CAREERS', to: '/careers' },
  { text: 'CONTACT', to: '/#contact' },
]

function renderLinkCol(arr) {
  const links = arr.map(({ to, text }, index) => {
    return <Link gaLabel={'Footer'} key={index} style={[styles.footerNavText, styles.link]} to={to} text={text}/>
  })
  return (
    <View style={styles.linkCol}>
      {links}
    </View>
  )
}

function Menu() {
  return (
    <View style={styles.menuWrapper}>
      <View style={styles.footerLinkWrapper}>
        {renderLinkCol(linksA)}
        {renderLinkCol(linksB)}
      </View>
    </View>
  )
}

function Disclaimers() {
  return (
    <View style={styles.disclaimersWrapper}>
      <Text style={styles.regDetailsText}>
        Visit us in Camden, London, UK.
      </Text>
      <Text style={styles.regDetailsText}>
        CodeLeap Ltd. is registered in England&nbsp;&&nbsp;Wales, company&nbsp;no.&nbsp;11967804.
      </Text>
      <Text variant={'inline'} style={styles.regDetailsText}>
        This website uses&thinsp;<Link openNewTab style={styles.link} gaLabel={'Footer'} to='/terms/licence'>third-party assets and software</Link>.
        By using our website you agree to the&thinsp;<Link openNewTab style={styles.link} gaLabel={'Footer'} to='/terms/website'>terms of use</Link>.
      </Text>
    </View>
  )
}

function Contact() {
  const year = moment().format('YYYY')
  return (
    <View style={styles.contactWrapper}>
      <Link gaLabel={'Footer'} gaAction={'codeleap contact email'} text={Settings.CONTACT_EMAIL} href={`mailto:${Settings.CONTACT_EMAIL}`} style={[styles.footerNavText, styles.link]}/>
      <Link gaLabel={'Footer'} gaAction={'codeleap contact phone'} text={Settings.CONTACT_PHONE} href={`tel:${Settings.CONTACT_PHONE}`} style={[styles.footerNavText, styles.link]}/>
      <Text style={styles.footerNavText} text={'Made with ♥️ in London, UK'}/>
      <Text style={styles.footerNavText} text={`©${year} CodeLeap Ltd.`}/>
    </View>
  )
}

function SocialMedia() {
  return (
    <View style={styles.socialMediaWrapper}>
      <Touchable gaLabel={'Footer'} style={styles.socialMediaIconWrapper} to={Settings.FACEBOOK_URL}>
        <FaFacebookSquare size={28} color={'white'}/>
      </Touchable>
      <Touchable gaLabel={'Footer'} style={styles.socialMediaIconWrapper} to={Settings.LINKEDIN_URL}>
        <FaLinkedin size={28} color={'white'}/>
      </Touchable>
    </View>
  )
}

export default function Footer() {
  return (
    <View style={styles.wrapper}>
      <CenterWrapper>
        <AnalyticsView gaLabel={'Footer'} style={styles.innerWrapper} reportOnEnter>
          <Image src={'assets/logos/codeleap_logo_white.png'} alt='CodeLeap' style={styles.logoImage}/>
          <View up={'mid'}>
            <View style={styles.innerWrapperRow}>
              <Menu/>
              <Contact/>
            </View>
            <View style={styles.separator}></View>
            <View style={styles.bottomRow}>
              <Disclaimers/>
              <SocialMedia/>
            </View>
          </View>
          <View down={'mid'} style={styles.innerWrapperColumn}>
            <Menu/>
            <View style={styles.separator}></View>
            <Contact/>
            <SocialMedia/>
            <Disclaimers/>
          </View>
        </AnalyticsView>
      </CenterWrapper>
    </View>
  )
}
