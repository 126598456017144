/* eslint no-restricted-imports: 'off' */
import { useEffect, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useUnmount } from 'react-use'

/**
 * useRedux()
 * Hook to listen to store changes
 *
 * @prop {string} objectRef ---> Eg. Session.profileId -> Perform automatic queries based on obj string
 * @prop {function} query ---> Use a custom query function to listen to store changes
 */


export const useRedux = (objectRef, query = null) => {
  const arr = objectRef.split('.')
  let storeData = []
  switch (arr.length) {
    case 0:
      warn('No params sent to useRedux')
      return null
    case 1:
      storeData = useSelector(s => s[arr[0]], shallowEqual)
      break
    case 2:
      storeData = useSelector(s => s[arr[0]][arr[1]], shallowEqual)
      break
    case 3:
      storeData = useSelector(s => s[arr[0]][arr[1]][arr[2]], shallowEqual)
      break
    case 4:
      storeData = useSelector(s => s[arr[0]][arr[1]][arr[2]][arr[4]], shallowEqual)
      break
    case 5:
      storeData = useSelector(s => s[arr[0]][arr[1]][arr[2]][arr[4]][arr[5]], shallowEqual)
      break
    default:
      warn('Too many nested objects in useRedux')
  }
  if (typeof query == 'function') {
    return storeData.filter(query)
  } else {
    return extractObjectById(storeData, query)
  }
}

const extractObjectById = (arr, id) => {
  if (arr && id) {
    const objs = arr.filter(i => i.id == id)
    if (!objs.length) {
      return []
    } else if (objs.length == 1) {
      return objs[0]
    } else {
      warn('Multiple objects found in useRedux! Duplicate data may exist.')
      return objs[0]
    }
  } else {
    return arr
  }
}

export const onMount = (func) => {
  useEffect(() => {
    return func()
  }, [])
}

export const onUnmount = useUnmount

export const onUpdate = (func, listeners = null) => {
  useEffect(() => {
    return func()
  }, listeners)
}

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}


// Debounce Hook
// https://usehooks.com/useDebounce/

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )

  return debouncedValue
}
