import { React, View, equals, useState, onUpdate } from 'lib'
import { Theme, TextStyles } from 'app'
import ReactTypist from 'react-typist'

/** @jsx jsx */
import { jsx } from '@emotion/react'


function Typist(props) {

  const isMobile = Theme.hooks.down('small')
  const [typing, setTyping] = useState(true)

  const restartTyping = () => {
    setTyping(false)
  }

  onUpdate(() => {
    if (!typing) {
      setTyping(true)
    }
  }, [typing])

  const renderPhrase = (phrase, key) => {
    return (
      <span css={styles.typing} key={key}>
        {phrase}
        <ReactTypist.Delay ms={2500}/>
        <ReactTypist.Backspace count={phrase.length}/>
      </span>
    )
  }


  return (
    <View cursor={cursor} style={styles.typingBox}>
      {typing && props.visible &&
      <div css={styles.typing}>
        <ReactTypist onTypingDone={restartTyping} avgTypingDelay={80}>
          <span css={styles.typing}>I want to {' '}</span>
          <ReactTypist.Delay ms={2500}/>
          {isMobile && <br/>}
          {props?.data?.map((p, k) => renderPhrase(p, k))}
          <ReactTypist.Backspace count={11}/>
        </ReactTypist>
      </div>
      }
    </View>
  )
}


function areEqual(prevProps, nextProps) {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(Typist, areEqual)

const cursor = {
  show: true,
  blink: true,
  element: '|',
  hideWhenDone: false,
  hideWhenDoneDelay: 1000,
}

const styles = {
  typingBox: {
    willChange: 'transform',
    transform: 'translate3d(0, 0, 0)',
    minHeight: 44,
  },
  typing: {
    color: 'white',
    fontFamily: Theme.fontFamily,
    ...TextStyles.compatibility.h5,
    ...TextStyles.text.h5,
    textAlign: 'left',
    [Theme.media.down('mid')]: {
      ...TextStyles.compatibility.h6,
      ...TextStyles.text.h6,
    },
  },
}
