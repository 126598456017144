import validator from 'validator'


export function truish(input) {
  const isValid = input && Boolean(input)
  return isValid ? null : 'Please enter a valid value'
}

export function field(input) {
  const isValid = input && input.length >= 3
  return isValid ? null : 'Please enter a valid value'
}

export function email(input) {
  const isValid = input && validator.isEmail(input)
  return isValid ? null : 'Please enter a valid email address'
}

export function username(input) {
  const regex = (/^[a-z0-9-_]+$/)
  const isValid = Boolean(input.match(regex))
  return isValid ? null : 'validateUsername'
}

export function password(input) {
  const isValid = input.length >= 6
  return isValid ? null : 'validatePassword'
}

export function phone(input) {
  // const isValid = input && isValidPhoneNumber(input)
  const regex = (/^[0-9-+()]*$/)
  const isValid = Boolean(input.match(regex)) && input.length > 9
  log({ input, isValid })
  return isValid ? null : 'Please enter a valid phone number'
}

export default {
  field,
  email,
  truish,
  username,
  password,
  phone,
}
