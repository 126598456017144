import { Theme, TextStyles } from 'app'

const defaultTransition = '300ms'

const wrapper = {
  //
  // default input wrapper
  //
  default: {
    default: {
      borderWidth: 2,
      position: 'relative',
      borderBottomStyle: 'solid',
      borderBottomColor: 'rgba(0, 0, 0, 0.1)',
      transition: defaultTransition,
    },
    get hover() {
      return {
        ...this.default,
        borderWidth: 2,
        borderBottomColor: 'rgba(0, 0, 0, 0.2)',
      }
    },
    get focus() {
      return {
        ...this.default,
        borderBottomColor: 'rgba(0, 0, 0, 0.5)',
      }
    },
    get populated() {
      return {
        ...this.default,
        borderBottomColor: 'rgba(0, 0, 0, 0.2)',
      }
    },
    get invalid() {
      return {
        ...this.default,
        borderBottomColor: 'rgba(255, 20, 0, 1)',
      }
    },
    get disabled() {
      return {
        ...this.default,
      }
    },
  },
  //
  // pill input wrapper
  //
  get pill() {
    return {
      ...this.default,
      default: {
        borderWidth: 2,
        ...Theme.borderColor('rgba(0, 0, 0, 0)'),
        borderStyle: 'solid',
        backgroundColor: '#f3f3f3',
        position: 'relative',
        borderRadius: Theme.values.borderRadius.small,
        transition: defaultTransition,
      },
      get hover() {
        return {
          ...this.default,
          borderRadius: Theme.values.borderRadius.small,
          backgroundColor: '#fff',
          borderWidth: 2,
          ...Theme.borderColor('rgba(0, 0, 0, 0.2)'),
        }
      },
      get focus() {
        return {
          ...this.default,
          borderRadius: 0,
          ...Theme.borderColor('rgba(0, 0, 0, 0)'),
          backgroundColor: '#fff',
          borderBottomColor: 'rgba(0, 0, 0, 0.5)',
        }
      },
      get populated() {
        return {
          ...this.default,
          ...Theme.borderColor('rgba(0, 0, 0, 0)'),
          borderRadius: 0,
          backgroundColor: '#fff',
          borderBottomColor: 'rgba(0, 0, 0, 0.2)',
        }
      },
      get invalid() {
        return {
          ...this.populated,
          ...Theme.borderColor('rgba(0, 0, 0, 0)'),
          borderBottomColor: 'rgba(255, 20, 0, 1)',
        }
      },
      get disabled() {
        return {
          ...this.default,
        }
      },
    }
  },
  //
  // pillDark input wrapper
  //
  get pillDark() {
    return {
      ...this.default,
      default: {
        borderWidth: 2,
        ...Theme.borderColor('rgba(0, 0, 0, 0)'),
        borderStyle: 'solid',
        position: 'relative',
        borderRadius: Theme.values.borderRadius.small,
        transition: defaultTransition,
        borderColor: Theme.colors.darkest,
        padding: Theme.spacing(1),
        paddingLeft: Theme.spacing(2),
        paddingRight: Theme.spacing(2),
      },
      get hover() {
        return {
          ...this.default,
          borderRadius: Theme.values.borderRadius.small,
          background: Theme.colors.darkest,
          borderWidth: 2,
          ...Theme.borderColor('rgba(0, 0, 0, 0.2)'),
        }
      },
      get focus() {
        return {
          ...this.default,
          background: Theme.colors.darkest,
          ...Theme.borderColor('rgba(0, 0, 0, 0)'),
          borderBottomColor: 'rgba(0, 0, 0, 0.5)',
        }
      },
      get populated() {
        return {
          ...this.default,
          borderColor: Theme.colors.darkest,
        }
      },
      get invalid() {
        return {
          ...this.populated,
          borderColor: 'rgba(255, 20, 0, 1)',
        }
      },
      get disabled() {
        return {
          ...this.default,
        }
      },
    }
  },
  //
  // plain input wrapper
  //
  get plain() {
    return {
      default: {
        position: 'relative',
        borderWidth: 0,
        borderStyle: 'none',
      },
    }
  },
  //
  // inverted input wrapper
  //
  get inverted() {
    return {
      ...this.default,
      default: {
        ...this.default.default,
        borderWidth: 2,
        borderBottomColor: 'rgba(255, 255, 255, 0.1)',
      },
      get hover() {
        return {
          ...this.default,
          borderWidth: 2,
          borderBottomColor: 'rgba(255, 255, 255, 0.2)',
        }
      },
      get focus() {
        return {
          ...this.default,
          borderBottomColor: 'rgba(255, 255, 255, 0.5)',
        }
      },
      get populated() {
        return {
          ...this.default,
          borderBottomColor: 'rgba(255, 255, 255, 0.2)',
        }
      },
      get invalid() {
        return {
          ...this.default,
          borderBottomColor: 'rgba(255, 20, 0, 1)',
        }
      },
      get disabled() {
        return {
          ...this.default,
        }
      },
    }
  },
  //
  // plainUnderline input wrapper
  //
  get plainUnderline() {
    return {
      ...this.default,
    }
  },
}

const label = {
  //
  // default input label
  //
  default: {
    default: {
      position: 'absolute',
      top: 0,
      left: 0,
      pointerEvents: 'none',
      transition: defaultTransition,
    },
    get hover() {
      return {
        ...this.default,
        color: '#333',
      }
    },
    get focus() {
      return {
        ...this.default,
        color: '#333',
      }
    },
    get populated() {
      return {
        ...this.default,
        color: '#333',
      }
    },
    get invalid() {
      return {
        ...this.default,
      }
    },
    get disabled() {
      return {
        ...this.default,
      }
    },
  },
  //
  // pill input label
  //
  get pill() {
    return {
      ...this.default,
      default: {
        position: 'absolute',
        color: '#555',
        fontSize: TextStyles.text.p1.fontSize,
        top: 16,
        left: Theme.spacing(2),
        pointerEvents: 'none',
        minHeight: 30,
        transition: defaultTransition,
      },
      get hover() {
        return {
          ...this.default,
          left: Theme.spacing(2),
          fontSize: TextStyles.text.p1.fontSize,
        }
      },
      get focus() {
        return {
          ...this.default,
          top: -2,
          left: 0,
          fontSize: TextStyles.text.p2.fontSize,
        }
      },
      get populated() {
        return {
          ...this.focus,
        }
      },
      get invalid() {
        return {
          ...this.populated,
        }
      },
      get disabled() {
        return {
          ...this.default,
        }
      },
    }
  },
  //
  // pillDark input label
  //
  get pillDark() {
    return {
      ...this.default,
      default: {
        position: 'absolute',
        color: '#555',
        fontSize: TextStyles.text.p1.fontSize,
        top: 16,
        left: Theme.spacing(2),
        pointerEvents: 'none',
        minHeight: 30,
        transition: defaultTransition,
      },
      get hover() {
        return {
          ...this.default,
          left: Theme.spacing(2),
          fontSize: TextStyles.text.p1.fontSize,
        }
      },
      get focus() {
        return {
          ...this.default,
          top: -2,
          left: 0,
          fontSize: TextStyles.text.p2.fontSize,
        }
      },
      get populated() {
        return {
          ...this.focus,
        }
      },
      get invalid() {
        return {
          ...this.populated,
        }
      },
      get disabled() {
        return {
          ...this.default,
        }
      },
    }
  },
  //
  // inverted input label
  //
  get inverted() {
    return {
      ...this.default,
      default: {
        color: '#fff',
      },
      get hover() {
        return {
          ...this.default,
        }
      },
      get focus() {
        return {
          ...this.default,
        }
      },
      get populated() {
        return {
          ...this.focus,
        }
      },
      get invalid() {
        return {
          ...this.populated,
        }
      },
      get disabled() {
        return {
          ...this.default,
        }
      },
    }
  },
}

const staticStyles = {
  //
  // default input staticStyles
  //
  default: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    innerWrapper: {
      flex: 1,
      cursor: 'text',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexGrow: 1,
    },
    label: {
      pointerEvents: 'none',
      flex: 1,
      display: 'flex',
      width: '100%',
      color: Theme.colors.darkest,
      // backgroundColor: 'blue',
      fontWeight: 'bold',
      fontSize: TextStyles.text.p2.fontSize,
    },
    input: {
      width: '100%',
      fontFamily: Theme.fontFamily,
      backgroundColor: 'transparent',
      minHeight: 40,
      fontSize: TextStyles.text.p1.fontSize,
      resize: 'none',
      '::placeholder': {
        fontSize: 18,
        color: '#aaa',
      },
    },
    validatorText: {
      marginTop: 6,
      color: 'red',
      fontSize: TextStyles.text.p2.fontSize,
    },
  },
  //
  // pill input staticStyles
  //
  get pill() {
    return {
      ...this.default,
      input: {
        ...this.default.input,
        minHeight: 30,
        marginTop: 18,
      },
    }
  },
  //
  // pill input staticStyles
  //
  get pillDark() {
    return {
      ...this.default,
      input: {
        ...this.default.input,
        color: 'white',
      },
      validatorText: {
        ...this.default.validatorText,
        marginLeft: Theme.spacing(3),
      },
    }
  },
  //
  // inverted input staticStyles
  //
  get inverted() {
    return {
      ...this.default,
      input: {
        ...this.default.input,
        color: '#fff',
        '::placeholder': {
          color: '#fff',
        },
      },
      label: {
        ...this.default.label,
        color: '#fff',
      },
    }
  },
  //
  // plain input staticStyles
  //
  get plain() {
    return {
      ...this.default,
      input: {
        ...this.default.input,
        minHeight: null,
        marginTop: null,
      },
    }
  },
  get disabled() {
    return {
      ...this.default,
    }
  },
  //
  // plainUnderline input staticStyles
  //
  get plainUnderline() {
    return {
      ...this.plain,
    }
  },
}

export default {
  wrapper,
  label,
  staticStyles,
}
