import { React, Image, View } from 'lib'
import { Theme } from 'app'

const image = 'assets/misc/rocket_science.jpeg'

export default function Scientists() {
  return (
    <View style={styles.wrapper}>
      <Image src={image} style={styles.image}/>
    </View>
  )
}

const styles = {
  wrapper: {
    marginBottom: Theme.spacing(2),
  },
  image: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: '100%',
    maxHeight: 300,
    objectFit: 'cover',
    borderRadius: Theme.values.borderRadius.small,
    [Theme.media.down('mid')]: {
      height: '60vw',
    },
  },
}
