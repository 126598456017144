/* eslint-disable no-console */
/* eslint-disable react/display-name */
import {
  View,
  Text,
  CenterWrapper,
  Image,
  React,
  AnalyticsView,
  Button,
} from 'lib'
import { Theme } from 'app'
import { demoDevice, styles } from '../_styles'
import DemoDevice from './_demoDevice'
import tweenProps from 'components/Reveal/tweenProps'
import { Reveal } from 'components'

const appStoreLogos = [
  'assets/logos/app-store.png',
  'assets/logos/play-store.png',
]

function AppDescription(props) {

  return (
    <View
      style={[
        styles.appDescriptionWrapper,
        props.position == 'left' && styles.appDescriptionWrapperLeft,
        props.style,
      ]}
    >
      <View up={'mid'}>
        <Image source={props.data.logo} style={[styles.appLogo, props?.data?.extraStyles?.logo]} alt={`${props.data.logo} app logo`}/>
      </View>
      <Text variant={'p1:responsive thin neutralWhite uppercase marginVertical:1'} text={props.data.subtitle}/>
      <Text variant={'h3 white marginVertical:2'} text={props.data.title}/>
      <View up={'mid'}>
        {props.data.body.map(renderParagraph)}
        {props?.button ? (
          renderButton()
        ) : (
          <Button
            gaLabel={'Portfolio'}
            variant={'inverted uppercase'}
            text={'Get a quote'}
            openNewTab={true}
            to={'/get-a-quote'}
          />
        )}
      </View>
      <View up={'mid'} variant={'flex marginTop:3'} style={styles.appStoreLogosWrapper}>
        {!props?.data?.doNotShowAppStoreLogos && appStoreLogos?.map((item) => <Image source={item} key={item} style={styles.appStoreLogos} alt={`${item} logo`}/>)}
      </View>
      {props?.data?.companies && (
        <View up={'mid'} style={styles.companiesWrapper}>
          <Text variant={'p2 white bold'} text={'Recommended by'}/>
          <View variant={'flex'}>
            {props?.data?.companies?.map((item) => <Image source={item} key={item} style={styles.companiesLogo} alt={`${item} logo`}/>)}
          </View>
        </View>
      )}
    </View>
  )
}

function renderParagraph(i, k) {
  const tiny = Theme.hooks.down('tiny')
  const variants = `${tiny ? 'p1:responsive marginBottom:2' : 'p1 marginBottom:3'} white`
  return <Text key={k} variant={variants} text={i}/>
}


function renderButton(props) {
  return (
    <View style={styles.buttonWrapper}>
      <props.button/>
    </View>
  )
}

function AppRow(props) {

  const { elemName, children, background, wrapperStyle } = props

  const { width, height } = Theme.hooks.size()
  const limitedPerformance = Tools.useLimitedPerformance()
  const demoHeight = DemoDevice.getDemoImageScale(width) * demoDevice.height
  const appRowStyles = { minHeight: demoHeight }

  return (
    <>
      <span id={elemName}/>
      <CenterWrapper style={styles.appRowCenterWrapper}>
        <View style={[styles.backgroundImageWrapper, wrapperStyle]}>
          <Reveal
            variant={'fixedBackground'}
            offset={height * -0.125}
            duration={height * 1.05}
            disabled={limitedPerformance > 0}
            fixedBackgroundOpacity={tweenProps.fixedBackgroundFullOpacity}
          >
            <View style={styles.imageInnerWrapper} className='willChange'>
              <Image
                alt={elemName}
                loading={'eager'}
                style={[styles.backgroundImage]}
                objectFit={'cover'}
                source={background}
                debug={props.debug}
              />
            </View>
          </Reveal>
        </View>
        <AnalyticsView
          gaLabel={'Portfolio'}
          gaAction={elemName}
          style={[
            styles.appRow,
            props.position == 'left' && styles.appRowLeft,
            appRowStyles,
          ]}
        >
          {children}
        </AnalyticsView>
      </CenterWrapper>
    </>
  )
}

const CardComponents = {
  AppRow,
  AppDescription,
}

export default CardComponents
