import { Button, React, Text } from 'lib'

const ctaVariant = 'inverted uppercase'

const landing = {
  overview: 'LONDON-BASED AWARD-WINNING DIGITAL AGENCY',
  title: () => {
    return (
      <>
        <h1>
          <Text variant={'h2 primary'}>
                Empowering{'\u00A0'}innovation, <br/> with expertly{'\u00A0'}crafted digital{'\u00A0'}products
          </Text>
        </h1>
      </>
    )
  },
  typist: [
    'build an AI solution for my business',
    'launch a mobile app for my startup',
    'create a VR experience to engage with customers',
    'build a custom data analytics insights dashboard',
    'develop a Computer Vision algorithm for my tech company',
  ],
}

const faq = [
  {
    title: 'What services do we offer?',
    content: [
      `CodeLeap is a software development agency that caters to businesses and startups, offering custom technology solutions tailored to their needs. CodeLeap's services include the development of bespoke mobile and web apps, advanced AI/ML solutions, and next-generation technologies like VR/AR, IoT, and Wearables. From conception to execution, CodeLeap is dedicated to delivering innovative solutions that propel businesses into the digital future and beyond.`,
    ],
  },
  {
    title: 'What types of projects does CodeLeap work with?',
    content: [
      `CodeLeap is industry-agnostic and works with a wide range of organisations to deliver on their strategic objectives.Our list of clients include businesses ranging from Fortune 500 companies to early-stage startups, SMEs and high-growth tech companies.`,
    ],
  },
  {
    title: 'What is included in your services?',
    content: [
      `We pride ourselves in being a key strategic tech partner that delivers real business results to our clients. From the get-go, we help clients clarify and define which business metrics must be achieved for a project, and strategise how we can deliver that together. With a clear set of objectives, we take care of everything needed for your specific project, ranging from Product Roadmap Planning, UX/UI Design, Development, QA Testing and Launch.`,
    ],
  },
  {
    title: 'How much does a project typically cost?',
    content: [
      `Prices for most web and mobile app projects start from £20,000 to £50,000 for an initial version, while AI/ML and next-gen solutions typically start from £50,000. Our pricing is determined based on the number of development days that it takes to develop a project, so it can vary greatly depending on the complexity of what you’re looking to build. Please get in touch and we will provide you with an initial price estimate within 24 hours.`,
    ],
  },
  {
    title: `What technologies do we use?`,
    content: [
      `Our preferred tech stack for web and mobile development is React and React Native, respectively. For the backend, we use Python coding language with Django framework, hosted on AWS servers. We’re open to working with other tech stacks too, please get in touch to discuss your project.`,
    ],
  },
  {
    title: `How to get a quote?`,
    content: [
      `Get in touch via our quote request form or via email with a brief description of your project and we will get back to you in 24 hours with an initial price estimate. If our pricing is aligned with your budget expectations, we will schedule a 1-hour Scoping Session free-of-charge to discuss your project in more detail. In 3-5 working days you can expect to receive a proposal for the development of your project, with a detailed breakdown of pricing, execution timeline, deliverables and payment schedule.`,
    ],
  },
]

const pricing = {
  project: {
    title: 'Per project',
    description: 'Ideal for small and well defined projects, we give you a fixed price for better predictability. A great choice if you know what to do and just want us to build it for you.',
    items: [
      {
        title: 'MVP',
        description: `We'll help you build the simplest version of your product so you can test\u00A0your\u00A0idea`,
        range: 'From £10\u00A0000 to £20\u00A0000',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        variants: '',
        items: [
          'Simple app or web app products',
          'Single main feature',
          'Basic designs',
          'Execution time from 3 months',
        ],
      },
      {
        title: 'Standard Project',
        description: 'Industry standard digital product, including custom designs and some\u00A0integrations',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        variants: '',
        range: 'From £20\u00A0000 to £50\u00A0000',
        items: [
          'Multiplatform products or simple AI/ML solutions',
          'Multiple core features',
          'Integrations with third-party services',
          'Execution time from 3 to 6 months',
        ],
      },
      {
        title: 'Premium',
        description: 'For those with unconventional ideas or looking for a superb user\u00A0experience',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        variants: 'white',
        range: 'Contact us',
        items: [
          'Highly-polished multiplatform products',
          'AI/ML, VR or other next-gen technologies',
          'Multiple third-party integrations',
          'Complex business logic and behaviour 6+ months execution time',
        ],
      },
    ],
  },
  monthly: {
    title: 'Per month',
    description: `Ideal for those who need flexibility to change regarding the project's scope and priorities. Also, a good choice for clients looking to work on complex and long-term projects.`,
    items: [
      {
        title: 'Level 1',
        description: `Perfect to build simple tech products or experiment with an MVP for those on a budget and no rush`,
        range: '£4\u00A0000/mo',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        variants: '',
        items: [
          'Includes 10 work days per month',
          'Full support for project management (CTO service)',
          'Flexible resource usage (design, frontend & backend) according to needs',
          'Senior professional dedicated part-time to your project',
          'Cheapest way to develop an app',
        ],
      },
      {
        title: 'Level 2',
        description: 'Recommended for most standard projects, good and efficient balance of cost and speed',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        variants: '',
        range: '£8\u00A0000/mo',
        items: [
          'Includes 20 work days per month',
          'Full support for project management (CTO service)',
          'Flexible resource usage (design, frontend & backend) according to needs',
          'Senior professional dedicated full-time to your project',
          'Supporting team with junior developers',
        ],
      },
      {
        title: 'Level 3',
        description: 'For highly ambitious projects that require multiple features to be developed simultaneously',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        variants: 'white',
        range: 'Contact us',
        items: [
          'Includes 20+ work days per month',
          'Full support for project management (CTO service) and consultancy',
          'Multiple senior developers dedicated to your project plus supporting team',
          'Special expertise (Artificial Intelligence, Computer Vision, etc...)',
          'Fastest way to develop complex products',
        ],
      },
    ],
  },
}


const DefaultHomepageData = {
  landing,
  pricing,
  faq,
}

export default DefaultHomepageData
