// gatsby-browser.js
import React from 'react'
import { Provider } from 'react-redux'
import { Controller } from 'react-scrollmagic'
import { gsap } from 'gsap'
import { SlowMo } from 'gsap/EasePack'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

gsap.registerPlugin(SlowMo)

import { store } from 'actions/reducers'

export const wrapWithProvider = ({ element }) => {
  return (
    <Controller refreshInterval={1000}>
      <Provider store={store}>
        {element}
      </Provider>
      <ToastContainer/>
    </Controller>
  )
}

export default wrapWithProvider
