import { React, Image } from 'lib'
import { Theme } from 'app'

export default function Astronaut() {
  return (
    <Image src={'assets/astronaut/codeleap_astronaut.png'} style={styles.astronaut}/>
  )
}

const styles = {
  astronaut: {
    margin: Theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 240,
    width: 240,
  },
}
