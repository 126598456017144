import Fetch from './Fetch'
import { Session } from 'actions'

function submitScannedItemReport(...props) {
  Tools.debounce(
    () => submitScannedItemReportDebounced(...props),
    'submitScannedItemReport',
    1000,
  )
}

async function submitScannedItemReportDebounced(data, onSuccess = () => null, onFailure = () => null) {
  const sessionData = await Session.getSessionData()
  const uploadData = {
    ...sessionData,
    ...data,
  }
  Fetch({
    url: 'lost_found/',
    method: 'POST',
    data: uploadData,
    options: {
      json: false,
    },
    successCallback: () => {
      console.log('Lost and found data submitted')
      log('Lost and found data submitted')
      onSuccess()
    },
    failureCallback: (e) => {
      console.log('Could not parse lost and found data', e)
      warn('Failed to submit lost found form', e)
      onFailure()
    },
  })
}

export default {
  submitScannedItemReport,
}
