export const servicesData = [
  {
    title: 'Mobile apps',
    description: 'We build cross-platform mobile applications following the best industry standards and the latest trends to create seamless user experiences.',
    image: 'assets/services/mobile.png',
    to: '/services/mobile',
  },
  {
    title: 'Websites',
    description: 'We create web products using latest tech stacks and innovative solutions: from simple websites to data-heavy highly-integrated solutions.',
    image: 'assets/services/web.png',
    to: '/services/web',
  },
  {
    title: 'AI',
    description: 'We specialise in crafting cutting-edge AI solutions tailored to optimise business outcomes and drive innovation.',
    image: 'assets/services/design.png',
    to: '/services/ai',
  },
  {
    title: 'Next-gen technologies',
    description: 'We love a good tech challenge. Our expertise ranges from Virtual Reality, Augmented Reality, Internet of Things (IoT), and many other solutions to bring your product to the next level.',
    image: 'assets/services/next-gen.png',
    to: '/services/next-gen',
  },
]
