import Fetch from './Fetch'
import { Analytics } from 'lib'
import { Session } from 'actions'

async function submit(data, onSuccess = () => null, onFailure = () => null) {
  const sessionData = await Session.getSessionData()
  const uploadData = {
    ...sessionData,
    ...data,
  }
  Fetch({
    url: 'enquiries/form_submit/',
    method: 'POST',
    data: uploadData,
    options: {
      json: false,
    },
    successCallback: () => {
      const params = {
        category: 'Enquiry',
        action: 'Contact form submit',
        label: 'Submit form (conversion)',
      }
      Analytics.event(params)
      onSuccess()
    },
    failureCallback: () => {
      error('Failed to submit contact form')
      onFailure()
    },
  })
}

async function submitQuote(data, onSuccess = () => null, onFailure = () => null) {
  const sessionData = await Session.getSessionData()
  const uploadData = {
    ...sessionData,
    ...data,
  }
  Fetch({
    url: 'enquiries/quote_form_submit/',
    method: 'POST',
    data: uploadData,
    options: {
      json: false,
    },
    successCallback: () => {
      const params = {
        category: 'Enquiry',
        action: 'Quote form submit',
        label: 'Submit form (conversion)',
      }
      Analytics.event(params)
      onSuccess()
    },
    failureCallback: () => {
      error('Failed to submit quote form')
      onFailure()
    },
  })
}

export default {
  submit,
  submitQuote,
}
