/* eslint no-restricted-imports: 'off' */
import { getStyles, isLowBrowserCompatibility } from 'lib/utils/StyleTools'
import { TextStyles } from 'app'
// import Fade from 'react-reveal/Fade'
import { useState } from 'lib'
import { WordReveal } from 'react-text-reveal'
import VisibilitySensor from 'react-visibility-sensor'

/** @jsx jsx */
import { jsx } from '@emotion/react'

/**
 * @exports <Text/>
 * Standard Text component. Use this for all typography.
 *
 * @prop {string} text ---> Actual content
 * @prop {string} variant ---> Text style variants, h1, p1, etc...
 * @prop {object} style ---> Text styles object
 *
 */

function Text(rawProps) {
  const { onPress, style, text, children, variant, ...props } = rawProps

  Tools.rejectProps({ onPress })

  const lowCompatibility = isLowBrowserCompatibility()

  const [visible, setVisible] = useState(false)

  const isPrimaryColor = variant?.includes('primary')
  const fadeIn = variant?.includes('fadeIn')

  const content = children || text

  const variants =
    isPrimaryColor && fadeIn ? variant.replace('primary', '') : variant

  const styles = getStyles(variants, TextStyles)

  const textStyles = [
    styles.text,
    lowCompatibility && styles.compatibility,
    style,
  ]

  // onMount(() => {
  //   setVisible(false)
  // })

  function onChangeVisibility(arg) {
    if (arg) setVisible(arg)
  }

  if (fadeIn == true && isPrimaryColor) {
    return (
      <VisibilitySensor onChange={onChangeVisibility} partialVisibility={true}>
        <div css={textStyles} className={'primary-background'}>
          <div className={'transparent-text'}>
            <WordReveal
              canPlay={visible}
              animateOpacity={true}
              wordOffsetDelay={55}
              copy={[content]}
              offset={'12px'}
              ease={'cubic-bezier(0,0.4,0.4,1)'}
            >
              {content}
            </WordReveal>
          </div>
        </div>
      </VisibilitySensor>
    )
  } else if (fadeIn) {
    return (
      <VisibilitySensor onChange={onChangeVisibility} partialVisibility={true}>
        <div css={textStyles}>
          <WordReveal
            canPlay={visible}
            animateOpacity={true}
            wordOffsetDelay={55}
            copy={[content]}
            offset={'12px'}
            ease={'cubic-bezier(0,0.4,0.4,1)'}
          >
            {content}
          </WordReveal>
        </div>
      </VisibilitySensor>
    )
  } else {
    const Component = props.component || 'div'
    return (
      <Component {...props} css={textStyles}>
        {content}
      </Component>
    )
  }
}

export default Text
