import Media from '../lib/utils/Media.js'
import { keyframes } from '@emotion/react'

export const Theme = {
  fontFamily: 'Open Sans,Helvetica',
  headerFontFamily: 'Heebo,Helvetica',
  breakpoints: {
    zero: 0, // where it all begins
    tiny: 350, // for small adjustment
    small: 600, // important
    mid: 900, // important
    largeish: 1050, // for small adjustment
    large: 1200, // important
    xlarge: 1400, // for small adjustment
    xxlarge: 1800, // important
    huge: 2559, // for small adjustment
  },
  values: {
    spacing: 8,
    headerBannerHeight: 0,
    headerMenuHeight: 'max(50px, min(calc(16px + 3vw), 64px))',
    headerMenuMargin: 'calc(max(50px, min(calc(16px + 3vw), 64px)) + 2*8px)',
    maxHeaderMenuHeight: 50,
    maxContentWidth: 1280,
    maxTextContentWidth: 800,
    footerHeight: 540,
    borderRadius: {
      tiny: 1,
      small: 5,
      smallish: 8,
      medium: 10,
      largeish: 16,
      large: 20,
      round: 9999,
    },
    borderWidth: '1px',
  },
  colors: {
    neutral2: '#F6F5F5',
    neutral3: '#E0DFDF',
    neutral4: '#C4C2C2',
    neutral5: '#2E2E2E',
    neutral7: '#807B7A',
    neutral8: '#3B3B3B',
    black: '#000', // off black
    black5: '#0000000D',
    black55: '#00000055',
    black75: '#000000BF',
    darkest: '#1E1E1E',
    darkish: '#2a2a2a',
    midDark: '#2d3436',
    white: '#fff',
    offwhite: '#888', // off white
    grey: '#777',
    ashGrey: '#BBB',
    neutralGrey: '#DDD',
    lightGrey: '#aaa',
    lightish: '#ccc',
    light: '#dedede',
    primary: 'linear-gradient(45deg, #8481FA, #38C9C8)',
    primarySolid: '#7695EC',
    background: '#f5f5f5',
    borderColor: '#D3D3D3',
    graniteGrey: '#666',
    charcoalGrey: '#3F3F3F',
    darkBackground: '#141414',
    neutralWhite: 'rgba(255, 255, 255, 1)',
  },
  keyframes: {
    spin: keyframes`
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    `,
  },
  get animations() {
    return {
      spin: `${this.keyframes.spin} 4s ease infinite`,
    }
  },

  //
  // Methods
  //
  media: { ...Media.queries },
  hooks: { ...Media.hooks },
  headerHeight: function () {
    return this.values.headerBannerHeight + this.values.headerMenuHeight
  },
  spacing: function (size) {
    return size * this.values.spacing
  },
  safeHorizontalPaddings: function () {
    return {
      xxlarge: this.spacing(16),
      large: this.spacing(12),
      largeish: this.spacing(8),
      mid: this.spacing(4),
      small: this.spacing(2),
    }
  },
  circle: (side) => ({
    width: side,
    height: side,
    borderRadius: side / 2,
    overflow: 'hidden',
  }),
  squircle: (side) => ({
    width: side,
    height: side,
    borderRadius: 8,
    overflow: 'hidden',
  }),
  semiRound: (side) => ({
    width: side,
    height: side,
    borderRadius: side / 3,
    overflow: 'hidden',
  }),
  square: (side) => ({
    width: side,
    height: side,
  }),
  paddingHorizontal: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      paddingLeft: size,
      paddingRight: size,
    }
  },
  paddingVertical: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      paddingTop: size,
      paddingBottom: size,
    }
  },
  marginHorizontal: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      marginLeft: size,
      marginRight: size,
    }
  },
  marginVertical: function (size, absolute = false) {
    size = absolute ? size : this.spacing(size)
    return {
      marginTop: size,
      marginBottom: size,
    }
  },
  debug: (color = 'red') => ({
    borderColor: color,
    borderWidth: 1,
    borderStyle: 'dotted',
  }),
  borderColor: (color = 'rgba(0, 0, 0, 1)') => ({
    borderTopColor: color,
    borderBottomColor: color,
    borderLeftColor: color,
    borderRightColor: color,
  }),

  //
  // Preset Style Patterns
  //
  // *** Display types ***
  //
  inline: {
    display: 'inline-block',
  },
  block: {
    display: 'block',
  },
  flex: {
    display: 'flex',
    flex: 1,
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  absolute: {
    position: 'absolute',
  },
  hidden: {
    display: 'none',
  },
  //
  // *** Layout ***
  //
  full: {
    width: '100%',
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  fullHeight: {
    width: '100%',
  },
  fullView: {
    width: '100vw',
    height: 'calc(100vh - calc(100vh - 100%))',
  },
  fullViewWidth: {
    width: '100vw',
  },
  fullViewHeight: {
    height: 'calc(100vh - calc(100vh - 100%))',
  },
  whole: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  centerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  listStyles: {
    overflow: 'auto',
  },
  column: {
    flexDirection: 'column',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  alignCenter: {
    alignItems: 'center',
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
  textCenter: {
    textAlign: 'center',
  },
  //
  // *** Styles ***
  //
  blur: {
    backdropFilter: 'blur(4px)',
    '-webkit-backdrop-filter': 'blur(4px)',
    transition: '500ms',
  },
  elevated: {
    boxShadow: '0px 0px 16px 16px #aaaaaa1a',
  },
  neumorphism: {
    boxShadow: '10px 10px 20px 0 #AEAEC077, -10px -10px 20px 0 #fff',
  },
  get borders() {
    return {
      borderWidth: this.values.borderWidth,
      borderRadius: this.values.borderRadius.small,
      borderColor: this.colors.border,
      borderStyle: 'solid',
    }
  },
}
