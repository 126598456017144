// eslint-disable-next-line no-restricted-imports
import React from 'react'
import CardComponents from '../_components/_card'
import DemoDevice from '../_components/_demoDevice'
import { Image, View } from 'lib'
import { Theme } from '_app'
import { Reveal } from 'components'
import { apps } from './_data'

const BeconWebCard = ({ onSelect, position = 'right' }) => {

  const { height } = Theme.hooks.size()

  const isLargeish = Theme.hooks.down('largeish')
  const upXLarge = Theme.hooks.up('xlarge')

  const data = apps['becon-web']

  return (
    <CardComponents.AppRow
      elemName={'becon'}
      background={data.background}
      position={position}
    >
      <View style={styles.wrapper}>
        <CardComponents.AppDescription data={data} position={position}/>
        {isLargeish ? (
          <DemoDevice.DemoRow data={data} onSelect={onSelect}/>
        ) : (
          <View style={styles.imagesWrapper}>
            <View style={styles.tabletWrapper}>
              <Reveal
                variant={'parallaxBackground'}
                amount={'15vh'}
                offset={height * -(upXLarge ? 0.45 : 0.2)}
                duration={height * 1.1}
                stopProgressAt={upXLarge ? 0.7 : 0.3}
              >
                <div className={'willChangeTransform'}>
                  <View style={styles.tabletInnerWrapper}>
                    <Image
                      loading={'eager'}
                      src={`assets/portfolio/becon/web/${upXLarge ? 'background-tablet' : 'mockup'}.png`}
                      style={styles.image}
                    />
                  </View>
                </div>
              </Reveal>
            </View>
            {upXLarge ? (
              <Reveal
                variant={'parallaxReverse'}
                amount={'10vh'}
                offset={height * -0.35}
                duration={height * 1.1}
                stopProgressAt={0.55}
              >
                <div className={'willChangeTransform'}>
                  <View style={styles.secondTabletWrapper}>
                    <Image
                      loading={'eager'}
                      src={'assets/portfolio/becon/web/background-tablet-2.png'}
                      style={styles.image}
                    />
                  </View>
                </div>
              </Reveal>
            ) : null}
          </View>
        )}
      </View>
    </CardComponents.AppRow>
  )
}

const styles = {
  wrapper: {
    [Theme.media.up('mid')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

    },
  },
  imagesWrapper: {
    [Theme.media.up('large')]: {
      display: 'flex',
      minWidth: '50%',
      justifyContent: 'flex-end',
    },
  },
  tabletWrapper: {
    [Theme.media.up('large')]: {
      zIndex: 9999,
    },
  },
  tabletInnerWrapper: {
    width: `calc(0.35 * min(95vw, 1500px))`,
    height: 'calc(0.5 * max(520px, min(75vh, 900px)))',
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    backgroundColor: Theme.colors.white,
    [Theme.media.up('xlarge')]: {
      position: 'absolute',
      right: -160,
    },
    [Theme.media.down('xlarge')]: {
      height: 'calc(0.75 * max(520px, min(75vh, 900px)))',
      width: `calc(0.5 * min(95vw, 1500px))`,
    },
    [Theme.media.down('large')]: {
      width: `calc(0.6 * min(95vw, 1500px))`,
      height: 'calc(0.7 * max(520px, min(75vh, 900px)))',
    },
    [Theme.media.down('largeish')]: {
      width: `calc(0.6 * min(95vw, 1500px))`,
      height: 'calc(0.6 * max(520px, min(75vh, 900px)))',
    },
  },
  secondTabletWrapper: {
    width: `calc(0.25 * min(95vw, 1500px))`,
    height: 'calc(0.85 * max(520px, min(75vh, 900px)))',
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    backgroundColor: Theme.colors.white,
    [Theme.media.down('large')]: {
      maxHeight: 'max(520px, min(75vh, 700px))',
    },
    [Theme.media.down('mid')]: {
      height: `calc(100% - ${Theme.spacing(4)}px)`,
      maxHeight: 'unset',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: Theme.values.borderRadius.medium,
    objectFit: 'fill',
  },
}

export default BeconWebCard
