const IMAGES_BASE_URL = 'assets/logos'

export const data = [
  `${IMAGES_BASE_URL}/dealerships.png`,
  `${IMAGES_BASE_URL}/autotrader.png`,
  `${IMAGES_BASE_URL}/dexters.png`,
  `${IMAGES_BASE_URL}/aws.png`,
  `${IMAGES_BASE_URL}/cedars-sinai.png`,
  `${IMAGES_BASE_URL}/jet-2-holidays.png`,
  `${IMAGES_BASE_URL}/dover-street-market.png`,
  `${IMAGES_BASE_URL}/lookers.png`,
  `${IMAGES_BASE_URL}/sweaty-betty.png`,
]


