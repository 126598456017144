/* eslint no-restricted-imports: 'off' */
import { getStyles } from 'lib'
import { StaticQuery, graphql } from 'gatsby'
import { default as GatsbyImage } from 'gatsby-image/withIEPolyfill'
import { ImageStyles } from 'app'

/** @jsx jsx */
import { jsx } from '@emotion/react'

/**
 * @exports <Image/>
 * Simple image component that uses gatsby sourcery internally
 *
 * @prop {string} src or source ---> Selected  animation value
 * @prop {object} style ---> Image style\
 *
 */

const dynamicAssetPrefixes = ['http', 'https', 'blob']

const Image = (props) => {
  const source = Tools.parseSourceUrl(props)
  if (!props.alt) {
    log('missing alt property in img', { source })
  }
  const isStatic = props.img != true && !dynamicAssetPrefixes.filter(i => source?.startsWith?.(i)).length
  if (isStatic) {
    const styles = props?.style && props.style
    return (
      <StaticQuery
        query={staticFluidQuery}
        render={(data) => {
          const image = data.images.edges.find(n => n.node.relativePath.includes(source))
          if (!image) return null
          if (props.debug) {
            deb.green({ image, data, props })
          }
          // log({ image })
          return (
            <GatsbyImage
              {...props}
              // alt={props.alt}
              // fadeIn={false}
              objectFit={props.objectFit || styles?.objectFit || 'contain'}
              objectPosition={props?.objectPosition}
              style={null}
              css={styles}
              fluid={image.node.childImageSharp.fluid}
            />
          )
        }}
      />
    )
  } else {
    const styles = getStyles(props.variant, ImageStyles)
    return (
      <img
        {...props}
        img={null}
        style={null}
        src={source}
        css={[styles.image, props.style]}
      />
    )
  }
}

const staticFluidQuery = graphql`
query {
  images: allFile {
    edges {
      node {
        relativePath
        name
        childImageSharp {
          fluid(maxWidth: 1800, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
`

export default Image

// fluid(maxWidth: 3840, quality: 70, srcSetBreakpoints: [768, 1024, 1440, 2560, 3840]) {
// ...GatsbyImageSharpFluid_withWebp
