import { Theme, Settings } from 'app'
import { onMount, url } from 'lib'
import callsites from 'callsites'
import queryString from 'query-string'
import { includes } from 'lodash'
import { useUserAgent } from '@oieduardorabelo/use-user-agent'
import { detect } from 'detect-browser'


const modules = {
  copy,
  hexToRgb,
  getMultipartFileUploadFormData,
  flatten,
  rejectProps,
  setQueryString,
  getQueryString,
  getIntersection,
  getAssetFiletype,
  ellipsis,
  parseSourceUrl,
  shouldReloadOnMount,
  shouldReload,
  shuffle,
  getAddressFromGeocode,
  makeRandomClassId,
  convertRange,
  useLimitedPerformance,
  throttle,
  scrollTo,
  loadScript,
  debounce,
  getMaxContentWidth,
}

function copy(string) {
  return JSON.parse(JSON.stringify(string))
}

function makeRandomClassId() {
  const length = 30
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return `element_${result}`
}

function convertRange(value, inRange, outRange) {
  const result = (value - inRange[0]) * (outRange[1] - outRange[0]) / (inRange[1] - inRange[0]) + outRange[0]
  if (result > outRange[1]) return outRange[1]
  else if (result < outRange[0]) return outRange[0]
  else return result
}

function ellipsis(string, length = 60) {
  return string?.length > length ? `${string?.substr(0, length)}...` : string
}

function hexToRgb(hex) {
  const result = (/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i).exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null
}

function getMultipartFileUploadFormData(data, file = null) {
  info({ data, file })
  const uploadData = {
    data: JSON.stringify({
      ...data,
      type: 'application/json',
    }),
    file,
  }
  const formData = new FormData()
  for (const key in uploadData) {
    formData.append(key, uploadData[key])
  }
  return formData
}

function flatten(object) {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(...Object.keys(o)
        .map(k => {
          if (isNaN(k) || o[k]) {
            return (typeof o[k] === 'object' ?
              _flatten(o[k]) :
              ({ [k]: o[k] }))
          } else {
            // warn('Failed flatten', { k, ok: o[k] })
          }
        },
        ),
      )
    }(object)),
  )
}

function rejectProps(props) {
  let funcName = ''
  try {
    funcName = callsites()[1].getFunctionName()
  } catch (err) {
    // pass
  }
  for (const k in props) {
    if (props[k]) {
      warn(`Prop "${k}" is not supported in <${funcName}/>`)
      // const cs = callsites()
      // for (const c in cs) {
      //   console.log('func', cs[c].getFunctionName())
      // }
    }
  }
}

function setQueryString(query) {
  const a = url()
  const next = queryString.stringifyUrl({ url: a.pathname, query })
  info({ url: a, next })
  window.history.pushState({ path: next }, Settings.WEBSITE_TITLE, next)
}

function getQueryString() {
  try {
    info({ values: queryString.parse(location.search) })
    return queryString.parse(location.search)
  } catch (err) {
    warn(err)
    return {}
  }
}

function getIntersection(arrayA = [], arrayB = []) {
  const intersect = arrayA.filter(item => arrayB.includes(item))
  return intersect
}

function getAssetFiletype(asset) {
  try {
    const imageTypes = ['png', 'jpg', 'jpeg', 'image']
    const videoTypes = ['mp4', 'video']
    const pdfTypes = ['pdf']
    let type = ''
    if (asset.file?.type) {
      type = asset.file.type.split('/')
    } else {
      type = [asset.file.split('.').pop()]
    }
    if (getIntersection(type, imageTypes).length) return 'image'
    if (getIntersection(type, videoTypes).length) return 'video'
    if (getIntersection(type, pdfTypes).length) return 'pdf'
  } catch (err) {
    warn(err)
    return null
  }
}

function parseSourceUrl(args) {
  let res = ''
  const address = args.source || args.src || (typeof args == 'string' && args) || null
  if (address && address?.startsWith?.('/media/')) {
    const tmp = address.substr(1, address.length)
    res = `${Settings.BASE_URL}${tmp}`
  } else if (address) {
    res = address
  } else {
    res = `https://picsum.photos/600?random=${Math.random() * 100}`
  }
  return res
}

function shouldReload() {
  const { reload } = Tools.getQueryString()
  if (reload && reload == 'true') {
    Tools.setQueryString({ reload: undefined })
    location.reload()
  }
}

function shouldReloadOnMount() {
  onMount(() => {
    shouldReload()
  })
}

function shuffle(array) {
  // Makes array random
  let currentIndex = array.length, temporaryValue, randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

function getAddressFromGeocode(geocodeResults) {
  if (geocodeResults.length) {
    const city = geocodeResults[0].address_components.find(o => includes(o.types, 'administrative_area_level_2'))
    const state = geocodeResults[0].address_components.find(o => includes(o.types, 'administrative_area_level_1'))
    const country = geocodeResults[0].address_components.find(o => includes(o.types, 'country'))
    return {
      city: city.long_name,
      state: state.short_name,
      country: country.long_name,
    }
  }
  return null
}

function useLimitedPerformance() {
  const { width } = Theme.hooks.size()
  const initialBrowser = detect()
  let uastring = ''
  try {
    uastring = window.navigator.userAgent
  } catch (err) {
    uastring = ''
  }
  const userAgent = useUserAgent(uastring)
  if (width > 2560) {
    return 1
  } else if (width < 900) {
    const platformName = (userAgent?.os?.name || initialBrowser?.os)?.toLowerCase() || ''
    if (platformName.includes('ios')) {
      return 2
    } else {
      return 3
    }
  } else {
    return 0
  }
}

let timerId
// Throttle function: Input as function which needs to be throttled and delay is the time interval in milliseconds
function throttle(func, delay) {
  // If setTimeout is already scheduled, no need to do anything
  if (timerId) {
    return
  }

  // Schedule a setTimeout after delay seconds
  timerId = setTimeout(function () {
    func()

    // Once setTimeout function execution is finished, timerId = undefined so that in <br>
    // the next scroll event function execution can be scheduled by the setTimeout
    timerId = undefined
  }, delay)
}

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2
  if (t < 1) return (c/2*t*t) + b
  t--
  return (-c/2 * ((t*(t-2)) - 1)) + b
}

function scrollTo(id, duration) {
  const element = document.getElementById(id)
  const to = element.getBoundingClientRect().bottom
  log({ element, to })
  // return null
  const start = (element && element.scrollTop) || window.pageYOffset,
    change = to - start,
    increment = 20
  let currentTime = 0

  const animateScroll = () => {
    currentTime += increment
    const val = Math.easeInOutQuad(currentTime, start, change, duration)
    window.scrollTo(0, val)
    if (currentTime < duration) {
      window.setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}

function loadScript(loadUrl, callback = () => null) {

  const script = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) { //IE
    script.onreadystatechange = function() {
      if (script.readyState == 'loaded' ||
                  script.readyState == 'complete') {
        script.onreadystatechange = null
        return callback()
      }
    }
  } else { //Others
    script.onload = function() {
      callback()
    }
  }

  script.src = loadUrl
  document.getElementsByTagName('head')[0].appendChild(script)
}

const debounceTimerId = []

function debounce(func, ref, delay) {
  if (debounceTimerId[ref]) {
    clearTimeout(debounceTimerId[ref])
  }
  debounceTimerId[ref] = setTimeout(function () {
    func()
    debounceTimerId[ref] = undefined
  }, delay)
}

function getMaxContentWidth () {
  const { width } = Theme.hooks.size()
  const safeHorizontalPaddings = Theme.safeHorizontalPaddings()
  const entries = Object.keys(safeHorizontalPaddings)
  const shouldApplyPadding = width < Theme.values.maxContentWidth + (safeHorizontalPaddings[entries[0]] * 2)
  let currentMedia = null
  let maxContentWidth = 0

  entries.forEach(item => {
    if (Theme.hooks.down(item, width)) {
      currentMedia = item
    }
  })

  if (!shouldApplyPadding) {
    maxContentWidth = Theme.values.maxContentWidth
  } else {
    maxContentWidth = width - (safeHorizontalPaddings[currentMedia] * 2)
  }

  const padding = !shouldApplyPadding ? (width - Theme.values.maxContentWidth) / 2 : safeHorizontalPaddings[currentMedia]

  return {
    width: `${maxContentWidth}px`,
    padding,
  }
}

global.Tools = modules

export default modules
