export const data = [
  {
    logo: `assets/logos/marble-logo.png`,
    app: 'AWS - Marble',
    title: 'Amelia Parmiter',
    description: `They are incredibly diligent in the quality of work they produce, and go above and beyond when it comes to solving issues faced within the project, and to ensure we meet strict project deadlines. You never feel like you are one of many clients, and they ensure they are always there to help with any need you have, no matter how big or small.`,
  },
  {
    logo: `assets/logos/neurometrics-logo.png`,
    app: 'Neurometrics-Diab',
    title: 'John',
    description: 'Working with CodeLeap was a very good experience from both a professional and personal point of view. These people know what they are doing and are both efficient and clever and made a good partner. I wouldn’t hesitate to recommend CodeLeap.',
  },
  {
    logo: `assets/logos/becon-logo.png`,
    app: 'Becon',
    title: 'Charlie',
    description: 'The enthusiasm, understanding of our mission, and passion from the first few minutes of the call were the deciding factors in choosing CodeLeap. Within 3 months of our app launching, we had over 1,500 downloads, appeared in leading publications from Forbes, The Metro, Marie Claire, Women’s Fitness and many more, and started raising further investment.',
  },
  {
    logo: `assets/logos/bearable-logo.png`,
    app: 'Bearable',
    title: 'James',
    description: 'CodeLeap made me feel like my options were limitless in terms of what could be achieved, which was critical to me as we were creating something very unique. I’m glad I chose Codeleap over a large corporation as the personable approach resulted in a smooth and pleasant experience.',
  },
  {
    logo: `assets/logos/xc-logo.png`,
    app: 'Glocal X',
    title: 'Edwin',
    description: 'I approached CodeLeap after losing faith with a larger (unnamed) app development company where my reported issues were treated as low priority. CodeLeap developed my app from scratch within 6 months for two (Android and iOS) platforms, and I found them to be very responsive and informative at each step. I would not hesitate to recommend CodeLeap.',
  },
  {
    logo: `assets/logos/cherry-logo-2.png`,
    app: 'Cherry',
    title: 'Johanne',
    description: `CodeLeap adds so much value and goes above and beyond what I expected. CodeLeap's team is very flexible with all the different requests we've put in front of them. They've also been great with the whole business side of things, and being able to forecast for this industry is really helpful. Overall, I'm completely satisfied with their work — they've been incredibly helpful.`,
  },
]
