/* eslint-disable no-console */
import { Theme } from '_app'
import { React, View, Text, CenterWrapper } from 'lib'
import Reveal from './Reveal'


const ServicesInfo = (props) => {

  const { data } = props
  const { height } = Theme.hooks.size()

  return (
    <View id={'servicesInfo'}>
      <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper}>
        <Reveal
          variant={'fadeInOut'}
          offset={height * -0.3}
          duration={height * 1.45}
        >

          <View variant={'flex center'}>
            <Text variant={`h3 primary textCenter`} text={data?.title} style={styles.itemTitle}/>
          </View>

          <View variant={'flex justifySpaceBetween marginTop:18'} style={styles.itemsWrapper}>
            {data?.items?.map?.(item => (
              <View style={styles.item} key={item?.title} variant={'paddingRight:6'}>
                <Text variant={`h5 bold white marginBottom:2`} text={item?.title}/>
                <Text variant={`p2 white marginBottom:2 neutralGrey`} text={item?.description}/>
              </View>
            ))}
          </View>


        </Reveal>
      </CenterWrapper>
    </View>
  )
}

const styles = {
  wrapper: {
    backgroundColor: Theme.colors.black,
    paddingTop: Theme.spacing(25),
    paddingBottom: Theme.spacing(35),
    [Theme.media.down('mid')]: {
      paddingTop: Theme.spacing(10),
      paddingBottom: Theme.spacing(10),
    },
  },
  innerWrapper: {
    ...Theme.column,
  },
  itemsWrapper: {
    [Theme.media.down('mid')]: {
      flexDirection: 'column',
    },
  },
  item: {
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(4),
    },
  },
  itemTitle: {
    maxWidth: '60%',
  },
}

export default ServicesInfo
