import { Theme } from 'app'

export const styles = {
  wrapper: {
    backgroundColor: 'white',
  },
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tiersContainerWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    flexGrow: 1,
  },
  tiersWrapper: {
    position: 'absolute',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    pointerEvents: 'none',
  },
  tiersWrapperSelected: {
    pointerEvents: 'auto',
  },
  tierWrapper: {
    display: 'flex',
    flex: 1,
  },
  centerTier: {
    marginLeft: Theme.spacing(6),
    marginRight: Theme.spacing(6),
    [Theme.media.down('xlarge')]: {
      marginLeft: Theme.spacing(4),
      marginRight: Theme.spacing(4),
    },
    [Theme.media.down('large')]: {
      marginLeft: Theme.spacing(2),
      marginRight: Theme.spacing(2),
    },
  },
  tilt: {
    background: Theme.colors.primary,
    padding: 3,
    borderRadius: Theme.values.borderRadius.small,
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,
    zIndex: 10,
    [Theme.media.down('mid')]: {
      marginLeft: Theme.spacing(2),
    },
  },
  tiltInner: {
    height: '100%',
    borderRadius: Theme.values.borderRadius.small - 2,
    display: 'flex',
    flexDirection: 'column',
    padding: Theme.spacing(4),
    [Theme.media.down('xlarge')]: {
      padding: Theme.spacing(3),
    },
    [Theme.media.down('large')]: {
      padding: Theme.spacing(2),
    },
    [Theme.media.down('mid')]: {
      minHeight: 480,
    },
  },
  separator: {
    height: 2,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: Theme.values.borderRadius.tiny,
    background: Theme.colors.primary,
    marginBottom: Theme.spacing(3),
    [Theme.media.down('xlarge')]: {
      marginBottom: Theme.spacing(1),
    },
  },
  separatorWhite: {
    background: 'white',
  },
  tierItemsWrapper: {
    textAlign: 'left',
    marginBottom: Theme.spacing(4),
    [Theme.media.down('xlarge')]: {
      marginBottom: Theme.spacing(2),
    },
  },
  tierItem: {
    marginTop: Theme.spacing(2),
    marginBottom: Theme.spacing(2),
    display: 'flex',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 'max(17vh, 150px)',
    [Theme.media.down('xlarge')]: {
      height: 'max(15vh, 140px)',
    },
  },
  bottomWrapper: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  descriptionText: {
    marginTop: '7%',
  },
  image: {
    width: 120,
    height: 120,
    overflow: 'visible',
    objectFit: 'contain',
    marginBottom: Theme.spacing(4),
    marginTop: Theme.spacing(4),
  },
  checkIconWrapper: {
    width: 20,
    height: 20,
    ...Theme.center,
  },
  tierSelectWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: Theme.spacing(6),
    [Theme.media.down('xlarge')]: {
      marginBottom: Theme.spacing(4),
    },
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(2),
    },
  },
  tierButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: Theme.spacing(4),
    marginRight: Theme.spacing(4),
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedDecoration: {
    background: Theme.colors.primary,
    marginTop: Theme.spacing(1),
    width: '120%',
    height: 6,
    borderTopRightRadius: 2,
    borderTopLeftRadius: 2,
    zIndex: 1,
    transform: 'scaleX(0)',
    opacity: 0,
    transition: '300ms',
  },
  selectedDecorationActive: {
    transform: 'scaleX(1)',
    opacity: 1,
    transition: '300ms',
  },
  wideSeparator: {
    width: '100vw',
    height: 1,
    backgroundColor: Theme.colors.light,
    position: 'absolute',
    bottom: 0,
    zIndex: 0,
  },
  pricingMethodDescription: {
    marginTop: Theme.spacing(10),
    marginBottom: Theme.spacing(10),
    [Theme.media.down('xlarge')]: {
      marginTop: Theme.spacing(6),
      marginBottom: Theme.spacing(6),
    },
  },
  customOfferWrapper: {
    marginTop: Theme.spacing(6),
    marginBottom: Theme.spacing(6),
    [Theme.media.down('xlarge')]: {
      marginTop: Theme.spacing(4),
      marginBottom: 0,
    },
    [Theme.media.down('mid')]: {
      marginLeft: Theme.spacing(4),
      marginRight: Theme.spacing(4),
    },
  },
  sliderWrapper: {
    opacity: 0,
    transform: 'translate3d(0, 30px, 0)',
    transition: '500ms',
    position: 'absolute',
    ...Theme.flex,
    pointerEvents: 'none',
  },
  sliderWrapperVisible: {
    opacity: 1,
    transform: 'translate3d(0, 0, 0)',
    transition: '500ms',
    pointerEvents: 'auto',
  },
  slider: {
    flex: 1,
    width: '100vw',
  },
}
