/* eslint-disable no-console */
import {
  View,
  React,
} from 'lib'
import { styles } from '../_styles'
import MobileComponents from '../_components/_mobile'
import PortfolioCards from '../_cards/_index'

const DefaultShowcase = ({ selected, setSelected, clear }) => {
  return (
    <View style={styles.wrapper} id={'portfolio'}>
      <View style={styles.contentWrapper}>
        <PortfolioCards.Bearable onSelect={() => setSelected('bearable-mobile')}/>
        <PortfolioCards.AWSGirlsTechSeries onSelect={() => setSelected('aws-girls-tech-series-web')}/>
        <PortfolioCards.KiteGeneral onSelect={() => setSelected('kite')}/>
        <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
      </View>
    </View>
  )
}

export default DefaultShowcase
