import { View, CenterWrapper, SEO, React, Analytics, useRef } from 'lib'
import { Footer, HeaderNavBar } from 'components'
import useTimeout from 'beautiful-react-hooks/useTimeout'
import 'app/stylesheets/css/Global.css'

function Page(props, ref) {

  const mounted = useRef(false)

  useTimeout(() => {
    if (!mounted.current) {
      log('Mount page', { pathname: window.location.pathname })
      Analytics.pageView(window.location.pathname)
      mounted.current = true
    }
  }, 1000)

  const Wrapper = props.fullWidth ? React.Fragment : CenterWrapper
  return (
    <View
      id={props.id}
      ref={ref}
      style={[styles.wrapper, props.whiteBackground && styles.whiteBackground]}
    >
      <SEO/>
      <Wrapper>
        <HeaderNavBar alwaysDark={props.whiteBackground} isHomepage={props?.isHomepage} service={props?.service}/>
        <View style={[props.center && styles.center]}>{props.children}</View>
      </Wrapper>
      <Footer/>
    </View>
  )
}

const PageComponent = React.forwardRef(Page)

export default PageComponent

const styles = {
  wrapper: {
    minHeight: '100vh',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  whiteBackground: {
    backgroundColor: '#fcfcfc',
  },
  center: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}
