// eslint-disable-next-line no-restricted-imports
import React from 'react'
import CardComponents from '../_components/_card'
import DemoDevice from '../_components/_demoDevice'
import { apps } from './_data'

const GoQuestMobileCard = ({ onSelect, position = 'left' }) => {

  const data = apps['go-quest-mobile']

  return (
    <CardComponents.AppRow
      elemName={'goQuest'}
      background={data.background}
      position={position}
    >
      <CardComponents.AppDescription data={data} position={position}/>
      <DemoDevice.DemoRow
        data={data}
        onSelect={onSelect}
      />
    </CardComponents.AppRow>
  )
}

export default GoQuestMobileCard
