import { React, Image, View } from 'lib'

const astronaut = 'assets/astronaut/nasa_astronaut_square.jpg'
const astronautTransparent = 'assets/astronaut/nasa_astronaut_square_transparent.png'

export default function NasaAstronaut(props) {
  const { transparent } = props
  const file = transparent ? astronautTransparent : astronaut
  return (
    <View>
      <Image alt={'CodeLeap - Nasa Astronaut'} src={file} style={styles.astronaut}/>
    </View>
  )
}

const styles = {
  astronaut: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 300,
    height: 300,
    objectFit: 'cover',
  },
}
