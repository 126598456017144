import { Theme } from 'app'


export const defaultScale = 0.6
export const demoDevice = { width: 427, height: 864 } // iPhone X from react-demoDevice-preview
export const macbook = { width: 1366, height: 768 } // MacBook from react-demoDevice-preview

const appRowHeight = 'max(660px, min(102vh, 1200px))'
const appRowHeightMobile = 'auto'

export const styles = {
  wrapper: {
    backgroundColor: 'black',
  },
  headerWrapper: {
    ...Theme.center,
    ...Theme.flex,
    flexDirection: 'column',
    marginBottom: Theme.spacing(8),
    marginTop: Theme.spacing(12),
    [Theme.media.down('large')]: {
      marginBottom: Theme.spacing(4),
      marginTop: Theme.spacing(8),
    },
  },
  headerInnerWrapper: {
    ...Theme.center,
    ...Theme.flex,
    flexDirection: 'column',
    maxWidth: Theme.values.maxTextContentWidth,
    position: 'relative',
  },
  headerDescWrapper: {
    maxWidth: 600,
    marginTop: Theme.spacing(8),
  },
  appRowCenterWrapper: {
    overflow: 'visible',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: appRowHeight,
    position: 'relative',
    [Theme.media.down('mid')]: {
      height: appRowHeightMobile,
    },
  },
  backgroundImageWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflow: 'visible',
    ...Theme.center,
    ...Theme.flex,
  },
  imageInnerWrapper: {
    ...Theme.center,
    ...Theme.flex,
    height: '100%',
    position: 'relative',
  },
  gradient: {
    borderRadius: Theme.values.borderRadius.small * 3,
    background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.30),  transparent)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  gradientLeft: {
    background: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.7))',
  },
  backgroundImage: {
    borderRadius: Theme.values.borderRadius.small * 3,
    overflow: 'hidden',
    maxWidth: 'min(95vw, 1500px)',
    maxHeight: 'max(520px, min(75vh, 900px))',
    height: '100vh',
    width: '100vw',
    [Theme.media.down('large')]: {
      maxWidth: 'min(95vw, 1500px)',
      maxHeight: 'max(520px, min(75vh, 700px))',
    },
    [Theme.media.down('mid')]: {
      height: `calc(100% - ${Theme.spacing(4)}px)`,
      maxHeight: 'unset',
    },
  },
  lastImageWrapper: {
    maxHeight: appRowHeight,
    [Theme.media.down('mid')]: {
      maxHeight: 'unset',
    },
  },
  contentWrapper: {
    marginBottom: Theme.spacing(12),
    [Theme.media.down('mid')]: {
      marginBottom: Theme.spacing(2),
    },
  },
  appRow: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    zIndex: 1,
    maxWidth: '100%',
    ...Theme.center,
    [Theme.media.down('mid')]: {
      flexDirection: 'column',
      marginTop: Theme.spacing(6),
      marginBottom: Theme.spacing(6),
    },
  },
  appRowLeft: {
    flexDirection: 'row-reverse',
  },
  demoRowWrapper: {
    ...Theme.flex,
    ...Theme.center,
    flex: 1,
    width: '100%',
    position: 'relative',
  },
  demoRow: {
    ...Theme.row,
    position: 'absolute',
    left: 0,
    [Theme.media.down('mid')]: {
      position: 'unset',
      ...Theme.center,
      flexDirection: 'column',
    },
  },
  demoRowLeft: {
    position: 'absolute',
    left: 'unset',
    right: 0,
    [Theme.media.down('mid')]: {
      position: 'unset',
    },
  },
  mobileCtaWrapper: {
    marginTop: Theme.spacing(2),
    opacity: 0.8,
  },
  appDescriptionWrapper: {
    paddingRight: Theme.spacing(9),
    justifyContent: 'center',
    alignItems: 'flex-start',
    maxWidth: 'min(35%, 500px)',
    [Theme.media.down('large')]: {
      paddingRight: Theme.spacing(6),
      maxWidth: 'min(45%, 500px)',
    },
    [Theme.media.down('mid')]: {
      ...Theme.flex,
      ...Theme.center,
      paddingRight: 0,
      maxWidth: '100%',
      flexDirection: 'column-reverse',
      marginBottom: Theme.spacing(1),
    },
  },
  appDescriptionWrapperLeft: {
    paddingRight: 0,
    paddingLeft: Theme.spacing(9),
    [Theme.media.down('large')]: {
      paddingLeft: Theme.spacing(6),
      paddingRight: 0,
    },
    [Theme.media.down('mid')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  deviceWrapper: {
    marginRight: Theme.spacing(2),
    [Theme.media.down('mid')]: {
      marginRight: 0,
    },
  },
  macbookWrapper: {
    height: macbook.height * defaultScale,
    width: macbook.width * defaultScale,
    marginRight: Theme.spacing(4),
  },
  macbookImage: {
    height: '118%',
    width: '108%',
    left: '-15px',
    top: '-20px',
  },
  buttonWrapper: {
    flex: 1,
    display: 'flex',
    marginTop: Theme.spacing(1),
    [Theme.media.down('mid')]: {
      marginTop: 0,
      marginBottom: Theme.spacing(2),
    },
  },
  neurometricsRow: {
    maxWidth: 600,
    marginLeft: Theme.spacing(12),
    [Theme.media.down('large')]: {
      maxWidth: 500,
      transform: 'scale(0.7)',
      margin: 0,
      left: -70,
      position: 'relative',
    },
  },
  neurometricsLandscape: {
    position: 'relative',
    left: '-200px',
    zIndex: 100,
  },
  neurometricsDevice: {
    position: 'relative',
    left: '-400px',
    top: '180px',
    zIndex: 100,
    transform: 'scale(0.8)',
  },
  scrollDownWrapper: {
    display: 'flex',
    width: '100%',
    position: 'absolute',
    top: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  unvealWrapper: {
    [Theme.media.down('large')]: {
      maxWidth: 720,
    },
  },
  subtitleText: {
    marginTop: Theme.spacing(8),
    [Theme.media.down('xlarge')]: {
      marginTop: Theme.spacing(6),
    },
    [Theme.media.down('large')]: {
      marginTop: Theme.spacing(4),
    },
    [Theme.media.down('small')]: {
      maxWidth: '80%',
      ...Theme.center,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  ourPortfolioText: {
    marginBottom: Theme.spacing(8),
    [Theme.media.down('xlarge')]: {
      marginBottom: Theme.spacing(4),
    },
    [Theme.media.down('large')]: {
      marginBottom: Theme.spacing(3),
    },

  },
  demoDeviceGroupRight: {
    marginTop: '40%',
    ...Theme.center,
    ...Theme.flex,
  },
  portfolioModalWrapper: {
    borderRadius: Theme.values.borderRadius.small,
    background: Theme.colors.primary,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    transition: '400ms',
    [Theme.media.down('mid')]: {
      background: '#00000055',
      backdropFilter: 'blur(4px)',
      position: 'fixed',
      pointerEvents: 'none',
      ...Theme.center,
      ...Theme.flex,
    },
  },
  portfolioModalWrapperActive: {
    transition: '400ms',
    opacity: 1,
    [Theme.media.down('mid')]: {
      pointerEvents: 'auto',
      zIndex: 99999,
    },
  },
  portfolioModal: {
    flex: 1,
    background: Theme.colors.primary,
    margin: Theme.spacing(4),
    padding: Theme.spacing(4),
    [Theme.media.down('tiny')]: {
      margin: Theme.spacing(3),
      padding: Theme.spacing(3),
    },
    borderRadius: Theme.values.borderRadius.small,
    transform: 'scale(0.9) translate3d(0, 0, 0)',
    transition: '400ms',
    opacity: 0,
  },
  portfolioModalActive: {
    opacity: 1,
    transform: 'scale(1) translate3d(0, 0, 0)',
    transition: '400ms',
  },
  seeMoreButtonWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    bottom: 0,
    right: 0,
    position: 'unset',
    marginTop: Theme.spacing(2),
  },
  appStoreLogosWrapper: {
    [Theme.media.down('small')]: {
      marginTop: Theme.spacing(2),
    },
  },
  appStoreLogos: {
    height: 40,
    width: 40,
    marginBottom: Theme.spacing(3),
    marginRight: Theme.spacing(2),
  },
  appLogo: {
    height: 60,
    width: 60,
    marginBottom: Theme.spacing(3),
  },
  companiesLogo: {
    height: 80,
    width: 80,
    marginBottom: Theme.spacing(),
    marginRight: Theme.spacing(2),
    [Theme.media.down('large')]: {
      width: 40,
      height: 40,
    },
  },
}
