import { Theme, ViewStyles } from 'app'

const styles = {
  ...ViewStyles,
  default: {
    display: 'inline-flex',
    cursor: 'pointer',
    textDecorationStyle: 'none',
    transition: '300ms',
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  growSmall: {
    transition: '100ms',
    '&:hover': {
      transform: 'scale(1.01)',
    },
  },
  underline: {
    textDecoration: 'underline',
  },
  plain: {
    cursor: 'unset',
    display: 'unset',
    textDecorationStyle: 'unset',
  },
  deep: {
    '&:active': {
      transform: 'scale(0.985)',
      transition: '120ms',
    },
    transition: '120ms',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.7,
  },
  highlight: {
    '&:hover': {
      backgroundColor: Theme.colors.highlight,
      opacity: 1,
    },
    '&:active': {
      backgroundColor: Theme.colors.pressed,
      opacity: 1,
    },
  },
}

export default styles
