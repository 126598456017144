/* eslint-disable no-console */
/* eslint-disable react/display-name */
import {
  View,
  Text,
  Button,
  React,
  Touchable,
} from 'lib'
import { styles } from '../_styles'
import { BsArrowRight } from 'react-icons/bs'
import { Theme } from '_app'
import { apps } from '../_cards/_data'
import { ServicesEnum } from 'components/BaseHomepage'

function renderParagraph(i, k) {
  const tiny = Theme.hooks.down('tiny')
  const variants = `${tiny ? 'p1:responsive marginBottom:2' : 'p1 marginBottom:3'} white`
  return <Text key={k} variant={variants} text={i}/>
}

function MobileAppDescriptionPopup(props) {
  const { selected, clear } = props
  return (
    <Touchable
      onPress={clear}
      style={[
        styles.portfolioModalWrapper,
        selected && styles.portfolioModalWrapperActive,
      ]}
    >
      <View
        style={[styles.portfolioModal, selected && styles.portfolioModalActive]}
      >
        <Text variant={'h3 white marginVertical:2'} text={props.data.title}/>
        {props.data.body.map(renderParagraph)}
        <Touchable style={styles.seeMoreButtonWrapper} to={'/#contact'}>
          <Text
            text={'Get in touch'}
            variant={'uppercase p2 white marginRight:1'}
          />
          <BsArrowRight color={'white'} size={20}/>
        </Touchable>
      </View>
    </Touchable>
  )
}

function renderMobileCta(props, onSelect) {
  return (
    <View style={styles.mobileCtaWrapper}>
      <View style={styles.buttonWrapper}>
        <Button
          onPress={onSelect}
          variant={'small uppercase outline'}
          text={'Learn more'}
        />
      </View>
    </View>
  )
}

const renderMobileModals = ({ selected, clear }) => {
  return Object.values(apps).map((item, index) => {
    const active = selected == Object.keys(apps)[index]
    return (
      <MobileAppDescriptionPopup
        key={index}
        data={item}
        clear={clear}
        selected={active}
      />
    )
  })
}

const MobileComponents = {
  renderMobileCta,
  renderMobileModals,
}

export default MobileComponents
