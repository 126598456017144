import {
  View,
  React,
} from 'lib'
import { styles } from '../_styles'
import MobileComponents from '../_components/_mobile'
import PortfolioCards from '../_cards/_index'

const AppsShowcase = ({ selected, setSelected, clear }) => {
  return (
    <View style={styles.wrapper} id={'portfolio'}>
      <View style={styles.contentWrapper}>
        <PortfolioCards.BeconMobile onSelect={() => setSelected('becon-mobile')}/>
        <PortfolioCards.Bearable onSelect={() => setSelected('bearable-mobile')}/>
        <PortfolioCards.KiteMobile onSelect={() => setSelected('kite-mobile')}/>
        <PortfolioCards.CherryMobile onSelect={() => setSelected('cherry-mobile')}/>
        <PortfolioCards.SkamperMobile onSelect={() => setSelected('skamper-mobile')}/>
        <PortfolioCards.GoQuestMobile onSelect={() => setSelected('go-quest-mobile')}/>
        <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
      </View>
    </View>
  )
}

export default AppsShowcase
