import { Cookies } from 'lib'
import isEqual from 'lodash/isEqual'

const manager = {
  servicesComponentFirst: Math.random() > 0.5,
  extraPortfolio: Math.random() > 0.5,
  shortForm: Math.random() > 0.5,
}

export const ABTestManagerCookiesKey = 'ABTestManager.manager'

function init() {
  const managerStoredCookies = Cookies.get(ABTestManagerCookiesKey)
  let parsedManagerCookiesJSON = null

  if (!managerStoredCookies) {
    Cookies.set(ABTestManagerCookiesKey, manager)
    return null
  }

  try {
    parsedManagerCookiesJSON = JSON.parse(managerStoredCookies)
  } catch (e) {
    parsedManagerCookiesJSON = managerStoredCookies
  }

  if (!isEqual(Object.keys(manager), Object.keys(parsedManagerCookiesJSON))) {
    const newManager = {
      ...manager,
      ...parsedManagerCookiesJSON,
    }
    Cookies.set(ABTestManagerCookiesKey, newManager)
  }
}

export default {
  init,
}
