import { Theme, TextStyles, ViewStyles } from '_app'

const wrapper = {
  ...ViewStyles,
  default: {
    ...Theme.center,
    background: Theme.colors.primary,
    backgroundSize: '150% 150%',
    borderRadius: Theme.values.borderRadius.small,
    display: 'inline-flex',
    whiteSpace: 'nowrap',
    padding: Theme.spacing(2),
    [Theme.media.down('large')]: {
      paddingLeft: Theme.spacing(2),
      paddingRight: Theme.spacing(2),
      padding: Theme.spacing(1.5),
    },
    boxSizing: 'border-box',
    '&:hover': {
      opacity: 0.8,
    },
  },
  small: {
    padding: Theme.spacing(1),
  },
  grey: {
    background: Theme.colors.grey,
  },
  primary: {
    background: Theme.colors.primary,
  },
  borderRadius: {
    borderRadius: Theme.values.borderRadius.small,
  },
  outline: {
    background: 'transparent',
    borderWidth: Theme.values.borderWidth,
    borderStyle: 'solid',
    borderColor: Theme.colors.light,
    color: Theme.colors.light,
    '&:hover': {
      color: Theme.colors.primary,
      opacity: 1,
    },
    '&:active': {
      background: 'transparent',
      opacity: 0.5,
    },
  },
  offwhite: {
    borderColor: Theme.colors.offwhite,
    color: Theme.colors.offwhite,
  },
  white: {
    background: 'white',
  },
  black5: {
    backgroundColor: Theme.colors.black5,
  },
  outlineOffwhite: {
    background: 'transparent',
    borderWidth: Theme.values.borderWidth,
    borderStyle: 'solid',
    borderColor: Theme.colors.offwhite,
    color: Theme.colors.offwhite,
    '&:hover': {
      borderColor: 'white',
      backgroundColor: 'white',
      opacity: 1,
    },
    '&:active': {
      opacity: 0.5,
    },
  },
  inverted: {
    background: 'white',
  },
  fullWidth: {
    width: '100%',
    ...Theme.center,
  },
  link: {
    background: 'transparent',
  },
  icon: {
    background: 'transparent',
    padding: Theme.spacing(2),
    '&:hover': {
      background: Theme.colors.highlight,
      opacity: 0.8,
    },
    '&:active': {
      background: Theme.colors.pressed,
      opacity: 1,
    },
    [Theme.media.down('mid')]: {
      padding: 0,
    },
  },
  clear: {
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },
  },
  get iconBackground() {
    return {
      ...this.icon,
      background: Theme.colors.highlight,
    }
  },
  get iconText() {
    return {
      ...this.icon,
      [Theme.media.down('mid')]: {
        padding: Theme.spacing(2),
      },
    }
  },
  get outlineWhite() {
    return {
      ...this.outline,
      borderWidth: 2,
      borderColor: 'white',
      '&:hover': {
        borderColor: 'white',
      },
    }
  },
  disabled: {
    background: Theme.colors.disabled,
    opacity: 0.6,
    '&:hover': {
      background: Theme.colors.disabled,
      opacity: 0.6,
    },
  },
  loading: {
    background: Theme.colors.disabled,
    opacity: 0.6,
    cursor: 'wait',
    '&:hover': {
      background: Theme.colors.disabled,
      opacity: 0.6,
    },
  },
  spacing: {
    paddingLeft: Theme.spacing(4),
    paddingRight: Theme.spacing(4),
  },
  round: {
    borderRadius: Theme.values.borderRadius.round,
  },
  debug: {
    ...Theme.debug('rgb(0, 255, 0)'),
  },
}

const text = {
  default: {
    ...TextStyles.text.p1,
    color: 'white',
    transition: '300ms',
  },
  small: {
    ...TextStyles.text.p2,
  },
  inverted: {
    color: 'black',
  },
  primaryText: {
    color: Theme.colors.primarySolid,
  },
  white: {
    color: 'white',
  },
  offwhite: {
    color: Theme.colors.offwhite,
  },
  outlineOffwhite: {
    color: Theme.colors.offwhite,
  },
  center: {
    ...Theme.center,
  },
  bold: {
    fontWeight: 'bold',
  },
  graniteGrey: {
    color: Theme.colors.graniteGrey,
  },
  link: {
    color: 'black',
    padding: Theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 'normal',
    ...TextStyles.text.default,
    ...TextStyles.text.link,
  },
  smallText: {
    fontSize: 16,
  },
  clear: {},
  uppercase: {
    textTransform: 'uppercase',
  },
  get outline() {
    return {
      color: 'inherit',
    }
  },
  get icon() {
    return {
      color: Theme.colors.dark,
    }
  },
  get iconText() {
    return {
      // paddingLeft: Theme.spacing(2),
    }
  },
  get outlineWhite() {
    return {
      color: 'white',
    }
  },
}

export default {
  wrapper,
  text,
}
