// eslint-disable-next-line no-restricted-imports
import React from 'react'
import CardComponents from '../_components/_card'
import DemoDevice from '../_components/_demoDevice'
import { Image, View } from 'lib'
import { Theme } from '_app'
import { Reveal } from 'components'
import { apps } from './_data'

const AWSCloudroomCard = ({ onSelect, position }) => {

  const { height } = Theme.hooks.size()
  const isLargeish = Theme.hooks.down('largeish')

  const data = apps['cloudroom-web']

  return (
    <CardComponents.AppRow
      elemName={'cloudroom'}
      background={data.background}
      position={position}
    >
      <CardComponents.AppDescription data={data} position={position}/>
      {isLargeish ? (
        <DemoDevice.DemoRow data={data} onSelect={onSelect}/>
      ) : (
        <Reveal
          variant={'parallaxReverse'}
          amount={'15vh'}
          offset={height * -0.35}
          duration={height * 1.1}
          stopProgressAt={0.55}
        >
          <div className={'willChangeTransform'}>
            <View style={styles.tabletWrapper}>
              <Image
                loading={'eager'}
                src={'assets/portfolio/aws-cloudroom/background-tablet.png'}
                style={styles.image}
              />
            </View>
          </div>
        </Reveal>
      )}
    </CardComponents.AppRow>
  )
}

const styles = {
  tabletWrapper: {
    width: `calc(0.6 * min(95vw, 1500px))`,
    height: 'calc(0.7 * max(520px, min(75vh, 900px)))',
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    [Theme.media.down('xlarge')]: {
      height: 'calc(0.75 * max(520px, min(75vh, 900px)))',
      width: `calc(0.5 * min(95vw, 1500px))`,
    },
    [Theme.media.down('large')]: {
      width: `calc(0.6 * min(95vw, 1500px))`,
    },
    [Theme.media.down('largeish')]: {
      height: 'calc(0.6 * max(520px, min(75vh, 900px)))',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: Theme.values.borderRadius.medium,
    objectFit: 'fill',
  },
}

export default AWSCloudroomCard
