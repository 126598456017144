import { React } from 'lib'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Settings } from 'app'

const SEO = ({ title, description, image, pathname, article, author, doNotTrack }) => {
  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultTitle,
            titleTemplate,
            defaultDescription,
            siteUrl,
            defaultImage,
            twitterUsername,
          },
        },
      }) => {
        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          image: `${siteUrl}${image || defaultImage}`,
          url: `${siteUrl}${pathname || Settings.IS_BROWSER ? window.location.pathname : '/'}`,
          doNotTrack: doNotTrack || false,
          type: article ? 'article' : 'website',
        }

        return (
          <Helmet
            title={seo.title}
            titleTemplate={titleTemplate}
          >

            {/* Technical stuff */}
            {/* <html lang='en' amp={undefined}/> */}
            <html lang='en'/>
            <meta charset='utf-8'/>
            <body className='root'/>
            {/* <script async src='https://cdn.ampproject.org/v0.js' type='text/javascript'></script> */}
            <script
              dangerouslySetInnerHTML={{
                __html: `
                (function () {
                  var e,i=["https://fastbase.com/fscript.js","FuE8ePYVL7","script"],a=document,s=a.createElement(i[2]);
                  s.async=!0,s.id=i[1],s.src=i[0],(e=a.getElementsByTagName(i[2])[0]).parentNode.insertBefore(s,e)
                })();`,
              }}
            />

            <script type='text/javascript'>
              {`window.addEventListener('mouseover', initLandbot, { once: true });
                  window.addEventListener('touchstart', initLandbot, { once: true });
                  var myLandbot;
                  function initLandbot() {
                    if (!myLandbot) {
                      var s = document.createElement('script');s.type = 'text/javascript';s.async = true;
                      s.addEventListener('load', function() {
                        var myLandbot = new Landbot.Livechat({
                          configUrl: 'https://storage.googleapis.com/landbot.pro/v3/H-1759730-Y9FM0AR42H72ZI4L/index.json',
                        });
                      });
                      s.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
                      var x = document.getElementsByTagName('script')[0];
                      x.parentNode.insertBefore(s, x);
                    }
                  }`}
            </script>

            <meta name='application-name' content='CodeLeap Website'/>
            <meta name='revisit-after' content='15 days'/>
            <meta httpEquiv='Content-Type' content='text/html; charset=utf-8'/>
            <meta httpEquiv='expires' content='43200'/>
            <meta property='og:locale' content='en_GB'/>
            <meta name='language' content='English'/>
            <meta name='coverage' content='Worldwide'/>
            {/* <meta name='revised' content={new Date()}/> */}

            <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no'/>
            <meta name='viewport' content='width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no'/>

            <link rel='icon' type='image/png' href='/images/favicon.png'/>
            <link rel='apple-touch-icon' type='image/png' href='/images/favicon.png'/>

            {/* NOTE Testing stuff */}
            {/* <link href='https://fonts.googleapis.com/css?family=Poppins:300,400,500&display=swap' rel='stylesheet'/> */}
            {/* <link href='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap' rel='stylesheet'/> */}
            {/* <link href='https://fonts.googleapis.com/css2?family=Commissioner:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap' rel='stylesheet'/> */}
            {/* <link href='https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap' rel='stylesheet'/> */}
            {/* <link href='https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700;800;900&display=swap' rel='stylesheet'/> */}

            {/* Fonts */}
            <link href='https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap' rel='stylesheet'/>
            <link href='https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap' rel='stylesheet'/>

            <link rel='stylesheet' type='text/css' charset='UTF-8' href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'/>
            <link rel='stylesheet' type='text/css' href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'/>

            {/* SEO */}
            <meta name='robots' content={doNotTrack ? 'noindex,nofollow' : 'index,follow'}/>
            <meta name='description' content={seo.description}/>
            <meta name='identifier-URL' content={seo.url}/>
            <meta name='image' content={seo.image}/>
            <link rel='canonical' href={seo.url}/>

            <meta name='og:site_name' content='CodeLeap'/>
            <meta property='title' content={seo.title}/>
            <meta property='pagename' content={seo.title}/>
            <meta property='og:title' content={seo.title}/>
            <meta property='og:type' content={seo.type}/>
            <meta property='og:description' content={seo.description}/>
            <meta property='og:url' content={seo.url}/>
            <meta property='og:image' content={seo.image}/>
            <meta property='og:image:secure_url' content={seo.image}/>
            <meta property='og:image:alt' content='CodeLeap Logo'/>
            <meta property='og:image:height' content='256'/>
            <meta property='og:image:width' content='256'/>


            {/* Blog articles */}
            {author && <meta name='author' content={author}/>}
            {author && <meta property='article:author' content={author}/>}
            {article && <meta property='article:section' content={'Software development & technology'}/>}

            {/* Social */}
            <meta name='twitter:card' content='summary_large_image'/>
            {twitterUsername && <meta name='twitter:creator' content={twitterUsername}/>}
            <meta name='twitter:title' content={seo.title}/>
            <meta name='twitter:description' content={seo.description}/>
            <meta name='twitter:image' content={seo.image}/>


            {/* Additional static SEO */}
            <meta name='og:locality' content='London, GB'/>
            <meta name='og:country-name' content='GB'/>
            <meta name='copyright' content='CodeLeap Ltd.'/>
            <meta name='Classification' content='Business'/>
            <meta name='og:email' content='hello@codeleap.co.uk'/>
            <meta name='theme-color' content='#000'/>

            {/* Other */}

          </Helmet>
        )
      }}
    />
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
