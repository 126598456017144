import { Theme } from '_app'
import { CenterWrapper, React, View, Text, Button, Image } from 'lib'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const description = [
  'Hi there!',
  `We're Victor & Vivian, CodeLeap's co-founders.`,
  'After years of experience in the UK tech scene, we teamed up to build an agency that truly works for founders and innovators – working in close partnership with our clients to explore what technology can achieve for businesses and for users.',
  'We are both innovation-driven techies with a passion for strategic collaboration. Together with our talented team of designers and engineers, we deliver the software solutions our client organisations need and deserve to thrive and flourish in the 21st century’s digital economy.',
  'Contact us to discuss your project – or come visit us at our offices in Camden!',
]

const carouselDuration = 20 // seconds

const WhoWeAre = () => {

  const isMobile = Theme.hooks.down('mid')
  const isSmall = Theme.hooks.down('small')

  const isTablet = isMobile && !isSmall

  const image = `assets/misc/founders_${isTablet ? 'tablet' : 'square'}.jpg`

  const ContentWrapper = isMobile ? CenterWrapper : View
  const ContentWrapperStyles = isMobile ? { contentContainerStyle: styles.contentWrapper } : { style: styles.contentWrapper }

  const mobileImageProps = isMobile && {
    objectPosition: 'top',
  }

  const breakpoints = {
    desktop: {
      breakpoint: {
        max: Theme.breakpoints.huge,
        min: Theme.breakpoints.mid,
      },
      items: 3,
    },
    tablet: {
      breakpoint: {
        max: Theme.breakpoints.mid,
        min: 0,
      },
      items: 2,
    },
  }


  return (
    <View style={styles.wrapper}>

      <CenterWrapper
        contentContainerStyle={styles.innerWrapper}
        centerContent={!isMobile}
      >

        <ContentWrapper {...ContentWrapperStyles}>

          <Text variant={`h2 medium`} style={styles.title} text={`Who We Are`}/>
          {description.map(item => <Text variant={`p1 black75 marginBottom:2`} key={item} text={item}/>)}
          <Text variant={`p1 black75`}> Best, <br/> V&V </Text>

          <View variant={'flex fullWidth'} style={styles.buttonsWrapper}>
            <Button
              variant={'primary fullWidth marginTop:5'}
              text={`Let's talk!`}
              to={'/#contact'}
              style={styles.button}
            />
          </View>

        </ContentWrapper>

        <View variant={'flex center'}>
          <Image
            source={image}
            style={styles.image}
            objectFit={'cover'}
            {...mobileImageProps}
          />
        </View>

      </CenterWrapper>

      <View style={styles.carouselWrapper}>
        <Carousel
          responsive={breakpoints}
          infinite
          autoPlay
          additionalTransfrom={0}
          arrows={null}
          customTransition={`all ${carouselDuration}s linear`}
          autoPlaySpeed={carouselDuration}
          transitionDuration={carouselDuration*1000}
          pauseOnHover={false}
        >
          <Image source={'assets/misc/codeleap_logo_black.png'} style={styles.codeleapLogo}/>
          <Image source={'assets/misc/codeleap_logo_white.png'} style={styles.codeleapLogo}/>
          <Image source={'assets/misc/codeleap_logo_black.png'} style={styles.codeleapLogo}/>
          <Image source={'assets/misc/codeleap_logo_white.png'} style={styles.codeleapLogo}/>
        </Carousel>
      </View>
    </View>
  )
}


const styles = {
  wrapper: {
    backgroundColor: Theme.colors.white,
    paddingTop: Theme.spacing(20),
    paddingBottom: Theme.spacing(12),
    [Theme.media.down('mid')]: {
      paddingTop: Theme.spacing(10),
      paddingBottom: Theme.spacing(10),
    },
    [Theme.media.down('small')]: {
      paddingBottom: Theme.spacing(5),
    },
  },
  innerWrapper: {
    display: 'flex',
    [Theme.media.down('mid')]: {
      flexDirection: 'column-reverse',
    },
  },
  contentWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    [Theme.media.up('mid')]: {
      paddingRight: Theme.spacing(10),
    },
    [Theme.media.down('mid')]: {
      paddingTop: Theme.spacing(10),
    },
    [Theme.media.down('small')]: {
      paddingTop: Theme.spacing(5),
    },
  },
  title: {
    marginBottom: Theme.spacing(5),
    [Theme.media.down('small')]: {
      marginBottom: Theme.spacing(2),
    },
  },
  button: {
    maxWidth: 220,
  },
  image: {
    width: '100%',
    height: '90%',
    [Theme.media.up('mid')]: {
      borderRadius: Theme.values.borderRadius.smallish,
    },
    [Theme.media.down('small')]: {
      height: 420,
    },
  },
  carouselWrapper: {
    marginTop: Theme.spacing(12),
    [Theme.media.up('mid')]: {
      marginTop: Theme.spacing(10),
    },
    [Theme.media.down('small')]: {
      marginTop: Theme.spacing(5),
    },
  },
  codeleapLogo: {
    objectFit: 'contain',
  },
}

export default WhoWeAre
