import { View, React } from 'lib'
import { Theme } from 'app'

export default function SectionWrapper(props) {
  const { style, contentContainerStyle, children } = props
  return (
    <View style={[styles.wrapper, style]}>
      <View style={[styles.innerWrapper, contentContainerStyle]}>
        {children}
      </View>
    </View>
  )
}

const styles = {
  wrapper: {
  },
  innerWrapper: {
    ...Theme.paddingVertical(12),
    [Theme.media.down('mid')]: {
      ...Theme.paddingVertical(8),
    },
  },
  innerWrapperMobile: {
  },
}
