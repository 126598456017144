// eslint-disable-next-line no-restricted-imports
import React from 'react'
import CardComponents from '../_components/_card'
import DemoDevice from '../_components/_demoDevice'
import { apps } from './_data'

const SkamperMobileCard = ({ onSelect, position = 'right' }) => {

  const data = apps['skamper-mobile']

  return (
    <CardComponents.AppRow
      elemName={'skamper'}
      background={data.background}
      position={position}
    >
      <CardComponents.AppDescription data={data} position={position}/>
      <DemoDevice.DemoRow
        data={data}
        onSelect={onSelect}
        position={'left'}
      />
    </CardComponents.AppRow>
  )
}

export default SkamperMobileCard
