import { ReactComponent as MobileIcon } from 'assets/icons/mobile.svg'
import { ReactComponent as WebIcon } from 'assets/icons/web.svg'
import { ReactComponent as NextGenIcon } from 'assets/icons/next-gen.svg'
import { ReactComponent as AIIcon } from 'assets/icons/ai.svg'


export const getButtonData = ({ service }) => {

  return [
    { text: 'HOME', to: '/' },
    {
      text: 'SERVICES',
      to: '/#services',
      items: [
        {
          title: 'Mobile apps',
          description: 'Cross-platform mobile apps for seamless user experiences',
          icon: MobileIcon,
          to: '/services/mobile',
        },
        {
          title: 'Next-gen',
          description: 'Innovative solutions to bring your product to the next level',
          icon: NextGenIcon,
          to: '/services/next-gen',
        },
        {
          title: 'Websites',
          description: 'From simple websites to data-heavy integrated web solutions',
          icon: WebIcon,
          to: '/services/web',
        },
        {
          title: 'AI solutions',
          description: 'Enhance business outcomes and drive innovation',
          icon: AIIcon,
          to: '/services/ai',
        },

      ],
    },
    { text: 'PORTFOLIO', to: service ? `/services/${service}/#portfolio` : '/#portfolio' },
    { text: 'PRICING', to: service ? `/services/${service}/#pricing` : '/#pricing' },
    { text: 'CONTACT', to: service ? `/services/${service}/#contact` : '/#contact' },
    { text: 'BLOG', to: '/blog/' },
    { text: 'GET A QUOTE', to: '/get-a-quote' },
  ]

}
