import {
  View,
  React,
} from 'lib'
import { styles } from '../_styles'
import MobileComponents from '../_components/_mobile'
import PortfolioCards from '../_cards/_index'

const AIShowcase = ({ selected, setSelected, clear }) => {
  return (
    <View style={styles.wrapper} id={'portfolio'}>
      <View style={styles.contentWrapper}>
        {/* <PortfolioCards.AutoImaging onSelect={() => setSelected('autoimaging')}/> */}
        <PortfolioCards.CherryMobile onSelect={() => setSelected('cherry')}/>
        <PortfolioCards.Bearable onSelect={() => setSelected('bearable')}/>
        <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
      </View>
    </View>
  )
}

export default AIShowcase
