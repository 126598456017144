/* eslint-disable no-console */
/* eslint-disable react/display-name */
import { View, Image, React } from 'lib'
import ReactDevicePreview from 'react-device-preview'
import { Theme } from 'app'
import { Reveal } from 'components'
import Slider from 'react-slick'
import { demoDevice, styles } from '../_styles'
import MobileComponents from './_mobile'
import './_devices.css'


const sliderSettings = {
  dots: false,
  adaptiveHeight: true,
  autoplay: true,
  autoplaySpeed: 3000,
}

function getDemoImageScale(width, adjust = 0.56) {
  let actualScale = adjust
  if (width <= Theme.breakpoints.tiny) {
    actualScale = adjust * 0.9
  } else if (width <= Theme.breakpoints.mid) {
    actualScale = adjust * 1.05
  } else if (width <= Theme.breakpoints.large) {
    actualScale =
        adjust *
        Tools.convertRange(
          width,
          [Theme.breakpoints.mid, Theme.breakpoints.large],
          [0.65, 0.85],
        )
  } else if (width <= Theme.breakpoints.xlarge) {
    actualScale =
        adjust *
        Tools.convertRange(
          width,
          [Theme.breakpoints.large, Theme.breakpoints.xlarge],
          [0.65, 0.9],
        )
  } else if (width <= Theme.breakpoints.xxlarge) {
    actualScale =
        adjust *
        Tools.convertRange(
          width,
          [Theme.breakpoints.xlarge, Theme.breakpoints.xxlarge],
          [0.9, 1],
        )
  }
  return actualScale
}

function DemoRow(props) {

  const { position, data } = props
  const { images } = data
  const { height, width } = Theme.hooks.size()
  const fits3 = Theme.hooks.up('large')
  const isLeft = position == 'left'

  const renderFirst = fits3 ? true : !isLeft
  const renderLast = fits3 ? true : isLeft
  const demoHeight = getDemoImageScale(width) * demoDevice.height
  const duration = height + demoHeight


  return (
    <View style={styles.demoRowWrapper}>
      <View style={[styles.demoRow, isLeft && styles.demoRowLeft]} up={'mid'}>
        {renderFirst && (
          <Reveal
            variant={'parallaxReverse'}
            amount={isLeft ? '25vh' : '5vh'}
            offset={height * -0.5}
            duration={duration}
          >
            <div className={'willChangeTransform'}>
              <Demo alt={`${data.title} demo 0`} image={images[0]}/>
            </div>
          </Reveal>
        )}
        <Reveal
          variant={'parallaxReverse'}
          amount={'15vh'}
          offset={height * -0.5}
          duration={duration}
        >
          <div className={'willChangeTransform'}>
            <Demo alt={`${data.title} demo 1`} image={images[1]}/>
          </div>
        </Reveal>
        {renderLast && (
          <Reveal
            variant={'parallaxReverse'}
            amount={isLeft ? '5vh' : '25vh'}
            offset={height * -0.5}
            duration={duration}
          >
            <div className={'willChangeTransform'}>
              <Demo alt={`${data.title} demo 2`} image={images[2]}/>
            </div>
          </Reveal>
        )}
      </View>
      <View style={[styles.demoRow, isLeft && styles.demoRowLeft]} down={'mid'}>
        <DemoSlider images={images} alt={data.title}/>
        {MobileComponents.renderMobileCta(props, props.onSelect)}
      </View>
    </View>
  )
}

function DemoSlider(props) {
  const { images, position, style, adjust, imageStyle, device, alt } = props
  const { width } = Theme.hooks.size()
  const actualScale = getDemoImageScale(width, adjust)
  const wrapperStyle = {
    width: demoDevice.width * actualScale,
    height: demoDevice.height * actualScale,
  }
  return (
    <View style={[styles.deviceWrapper, wrapperStyle, style]}>
      <ReactDevicePreview
        device={device || 'iphonex'}
        scale={actualScale}
        position={position || 'portrait'}
      >
        <Slider {...sliderSettings}>
          {images.map((image, index) => (
            <Image
              alt={`${alt} demo ${index}`}
              key={index}
              loading={'eager'}
              src={image}
              style={imageStyle}
            />
          ))}
        </Slider>
      </ReactDevicePreview>
    </View>
  )
}

function Demo(props) {
  const { image, alt, position, style, adjust, imageStyle, device, scale } = props
  const { width } = Theme.hooks.size()
  const actualScale = getDemoImageScale(width, adjust)
  const wrapperStyle = {
    width: demoDevice.width * actualScale,
    height: demoDevice.height * actualScale,
  }
  return (
    <View style={[styles.deviceWrapper, wrapperStyle, style]}>
      <ReactDevicePreview
        device={device || 'iphonex'}
        scale={scale ? scale : actualScale}
        position={position || 'portrait'}
      >
        <Image alt={alt} loading={'eager'} src={image} style={imageStyle}/>
      </ReactDevicePreview>
    </View>
  )
}

const DemoDevice = {
  Demo,
  DemoSlider,
  DemoRow,
  getDemoImageScale,
}

export default DemoDevice
