/* eslint no-restricted-imports: 'off' */
import { React, getStyles, Media } from 'lib'
import { ViewStyles } from 'app'

/** @jsx jsx */
import { jsx } from '@emotion/react'

/**
 * @exports <View/>
 * Default View component. Use this instead of divs.
 *
 * @prop {string} variant ---> View style variants
 * @prop {object} style ---> View styles object
 * @prop {function} onHover ---> Triggered when hovered, returns bool
 * @prop {string} is ---> Shows View only on media size
 * @prop {string} not ---> Hides View only on media size
 * @prop {string} up ---> Shows View on upwards of media size
 * @prop {string} down ---> Shows View on downwards of media size
 *
 */


function View(rawProps, ref) {
  const {
    to,
    onPress,
    onHover,
    onClick,
    style,
    children,
    is,
    not,
    up,
    down,
    ignoreWarnings,
    ...props
  } = rawProps

  let { variant } = rawProps

  if (!ignoreWarnings) Tools.rejectProps({ onPress, to, onClick })

  const platformMediaQuery = Media.renderToPlatformQuery({ is, not, up, down })
  const shouldRenderToPlatform = Media.shouldRenderToPlatform({ is, not, up, down })
  // if (is|| not|| up|| down) {

  if (props.debug) {
    log({ rawProps, props, shouldRenderToPlatform, platformMediaQuery })
  }
  if (!shouldRenderToPlatform) return null

  const handleHover = (hover) => {
    if (onHover) onHover(hover)
  }

  if (props.debug) {
    variant = variant + ' debug'
  }

  const styles = getStyles(variant, { ViewStyles })

  const divStyles = [
    styles.ViewStyles,
    style,
    platformMediaQuery,
  ]

  if (props.debug) {
    log({ rawProps, props, divStyles })
  }

  return (
    <div
      {...props}
      ref={ref}
      css={divStyles}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      {children}
    </div>
  )
}

const ViewComponent = React.forwardRef(View)

// ViewComponent.whyDidYouRender = Settings.WARN_WHY_DID_YOU_RENDER
ViewComponent.whyDidYouRender = false

export default ViewComponent


