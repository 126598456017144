/* eslint-disable no-restricted-imports */
import { React } from 'react'
import { Button } from 'lib'
import { Theme } from '_app'

export const apps = {
  'autoimaging-mobile': {
    title: 'AutoImaging',
    subtitle: '100% AI Automated Video',
    logo: 'assets/logos/autoimaging-logo.png',
    body: [
      'AutoImaging is a leading provider of car imaging solutions for the auto industry. Its AI Imagery Software uses Computer Vision techniques to offer real-time imaging quality control, automated backdrops replacement and 100% automated and bespoke branded video production.',
    ],
    background: 'assets/portfolio/autoimaging/background.png',
    images: [
      'assets/portfolio/autoimaging/mobile.png',
    ],
  },
  'cloudroom-web': {
    title: 'AWS CloudRoom ',
    subtitle: 'VOLUNTEERING EVENTS PLATFORM',
    body: [
      'CloudRoom is Amazon’s global initiative for helping students around the world learn more about the Cloud and its career opportunities.',
    ],
    logo: 'assets/logos/aws-white-logo.png',
    extraStyles: {
      logo: {
        width: 65,
        height: 65,
        marginBottom: Theme.spacing(-0.5),
      },
    },
    doNotShowAppStoreLogos: true,
    background: 'assets/portfolio/aws-cloudroom/aws-background.jpg',
    images: [
      'assets/portfolio/aws-cloudroom/device-1.png',
      'assets/portfolio/aws-cloudroom/device-2.png',
      'assets/portfolio/aws-cloudroom/device-3.png',
    ],
  },
  'aws-girls-tech-series-web': {
    title: 'AWS Girls Tech Series ',
    subtitle: 'ESG EVENTS PLATFORM',
    body: [
      'In partnership with Marble LND, CodeLeap developed Amazon’s Girls Tech Series program, a free learning experience that focuses on each of the STEAM areas with activities designed to engage, connect and activate future female innovators.',
    ],
    logo: 'assets/logos/aws-white-logo.png',
    doNotShowAppStoreLogos: true,
    background: `assets/portfolio/aws-girls-tech-series/background.png`,
    images: [
      'assets/portfolio/aws-girls-tech-series/device-1.png',
      'assets/portfolio/aws-girls-tech-series/device-2.png',
      'assets/portfolio/aws-girls-tech-series/device-3.png',
    ],
  },
  'becon-mobile': {
    title: 'Becon',
    subtitle: 'Automated Walking Safety',
    body: [
      'Becon is an emergency alert app for walking journeys that uses automatic triggers to keep its users safe, whilst maintaining their desire for independence and privacy.',
    ],
    logo: 'assets/logos/becon-logo-transparent.png',
    companies: [
      'assets/logos/marie-claire.png',
      'assets/logos/forbes.png',
      'assets/logos/metro.png',
      'assets/logos/evening-standard.png',
    ],
    background: 'assets/portfolio/becon/becon-background.jpg',
    images: [
      'assets/portfolio/becon/mobile/becon1.png',
      'assets/portfolio/becon/mobile/becon2.png',
      'assets/portfolio/becon/mobile/becon3.png',
    ],
    button: () => {
      return (
        <Button
          gaLabel={'Portfolio'}
          gaAction={`/becontheapp.com from Becon`}
          variant={'inverted uppercase'}
          text={'Learn more'}
          openNewTab={true}
          to={'https://becontheapp.com/'}
        />
      )
    },
  },
  'becon-web': {
    title: 'Becon',
    subtitle: 'Automated Walking Safety',
    body: [
      'Becon is an emergency alert app for walking journeys that uses automatic triggers to keep its users safe, whilst maintaining their desire for independence and privacy.',
    ],
    logo: 'assets/logos/becon-logo-transparent.png',
    companies: [
      'assets/logos/marie-claire.png',
      'assets/logos/forbes.png',
      'assets/logos/metro.png',
      'assets/logos/evening-standard.png',
    ],
    background: 'assets/portfolio/becon/becon-background.jpg',
    images: [
      'assets/portfolio/becon/web/device-1.png',
      'assets/portfolio/becon/web/device-2.png',
      'assets/portfolio/becon/web/device-3.png',
    ],
    button: () => {
      return (
        <Button
          gaLabel={'Portfolio'}
          gaAction={`/becontheapp.com from Becon`}
          variant={'inverted uppercase'}
          text={'Learn more'}
          openNewTab={true}
          to={'https://becontheapp.com/'}
        />
      )
    },
  },
  'bearable-mobile': {
    title: 'Bearable',
    subtitle: 'Easy Health Diary With Stats',
    body: [
      'Bearable is a mobile app that helps people keep track of their health and well-being.',
    ],
    logo: 'assets/logos/bearable-logo-2.png',
    companies: [
      'assets/logos/web-md.png',
      'assets/logos/us-pain-foundation.png',
      'assets/logos/forbes.png',
      'assets/logos/psych-central.png',
    ],
    background: `assets/portfolio/bearable/bearable-background.jpg`,
    images: [
      'assets/portfolio/bearable/bearable2.jpg',
      'assets/portfolio/bearable/bearable3.jpg',
      'assets/portfolio/bearable/bearable4.jpg',
    ],
  },
  'cherry-mobile': {
    title: 'Cherry Dating',
    subtitle: 'FACIAL RECOGNITION FOR USER SAFETY',
    body: [
      'Cherry is a dating app that keeps all  members safe by verifying their accounts using an official ID and security selfie, while cross-checking those images using facial recognition against users’ own profile photos within the app.',
    ],
    logo: 'assets/logos/cherry-logo.png',
    background: `assets/portfolio/cherry/background.png`,
    images: [
      `assets/portfolio/cherry/mobile/cherry1.png`,
      `assets/portfolio/cherry/mobile/cherry2.png`,
      `assets/portfolio/cherry/mobile/cherry3.png`,
    ],
  },
  'go-quest-mobile': {
    title: 'Go Quest Adventure',
    subtitle: 'DISCOVER, EXPLORE, CONQUER',
    body: [
      'Go Quest Adventures is a mobile app that offers fun, challenging and interactive City Quests, all self guided through its friendly mobile app.',
    ],
    logo: 'assets/logos/goQuest-logo.png',
    background: 'assets/portfolio/go-quest/goQuest-background.jpg',
    images: [
      'assets/portfolio/go-quest/mobile/goQuest1.png',
      'assets/portfolio/go-quest/mobile/goQuest2.png',
      'assets/portfolio/go-quest/mobile/goQuest3.png',
    ],
  },
  'go-quest-web': {
    title: 'Go Quest Adventure',
    subtitle: 'DISCOVER, EXPLORE, CONQUER',
    body: [
      'Go Quest Adventures is a mobile app that offers fun, challenging and interactive City Quests, all self guided through its friendly mobile app.',
    ],
    logo: 'assets/logos/goQuest-logo.png',
    background: 'assets/portfolio/go-quest/goQuest-background.jpg',
    images: [
      'assets/portfolio/go-quest/web/device-1.png',
      'assets/portfolio/go-quest/web/device-2.png',
      'assets/portfolio/go-quest/web/device-3.png',
    ],
  },
  'kite-general': {
    title: 'Kite Insights',
    subtitle: 'ESG CORPORATE TRANSPARENCY',
    logo: 'assets/logos/kite-logo.png',
    body: [
      'Kite Insights is a leading ESG consultancy firm in London who teamed up with CodeLeap to launch Hurd – a mobile app that brings transparency to large organisations’ climate policies and give employees a voice.',
    ],
    extraStyles: {
      logo: {
        width: 100,
        height: 100,
        marginBottom: Theme.spacing(-0.5),
      },
    },
    background: `assets/portfolio/kite/background.png`,
    images: [
      'assets/portfolio/kite/mobile/kite1.png',
      'assets/portfolio/kite/mobile/kite2.png',
      'assets/portfolio/kite/mobile/kite3.png',
    ],
  },
  'kite-mobile': {
    title: 'Kite Insights',
    subtitle: 'ESG CORPORATE TRANSPARENCY',
    logo: 'assets/logos/kite-logo.png',
    body: [
      'Kite Insights is a leading ESG consultancy firm in London who teamed up with CodeLeap to launch Hurd – a mobile app that brings transparency to large organisations’ climate policies and give employees a voice.',
    ],
    extraStyles: {
      logo: {
        width: 100,
        height: 100,
        marginBottom: Theme.spacing(-0.5),
      },
    },
    background: `assets/portfolio/kite/background.png`,
    images: [
      'assets/portfolio/kite/mobile/kite1.png',
      'assets/portfolio/kite/mobile/kite2.png',
      'assets/portfolio/kite/mobile/kite3.png',
    ],
  },
  'kite-web': {
    title: 'Kite Insights',
    subtitle: 'ESG CORPORATE TRANSPARENCY',
    logo: 'assets/logos/kite-logo.png',
    body: [
      'Kite Insights is a leading ESG consultancy firm in London who teamed up with CodeLeap to launch Hurd – a mobile app that brings transparency to large organisations’ climate policies and give employees a voice.',
    ],
    extraStyles: {
      logo: {
        width: 100,
        height: 100,
        marginBottom: Theme.spacing(-0.5),
      },
    },
    background: `assets/portfolio/kite/background.png`,
    images: [
      'assets/portfolio/kite/web/device-1.png',
      'assets/portfolio/kite/web/device-2.png',
      'assets/portfolio/kite/web/device-1.png',
    ],
  },
  'skamper-mobile': {
    title: 'Skamper',
    subtitle: 'SHARING THE JOY OF RUNNING',
    body: [
      'Skamper is a mobile app that encourage runners to test themselves against the targets that matter to them, at a time that suits them.',
    ],
    logo: 'assets/logos/skamper-logo.png',
    background: 'assets/portfolio/skamper/skamper-background.jpg',
    images: [
      'assets/portfolio/skamper/mobile/skamper1.png',
      'assets/portfolio/skamper/mobile/skamper2.png',
      'assets/portfolio/skamper/mobile/skamper3.png',
    ],
  },
}
