import {
  View,
  React,
} from 'lib'
import { styles } from '../_styles'
import MobileComponents from '../_components/_mobile'
import PortfolioCards from '../_cards/_index'

const WebShowcase = ({ selected, setSelected, clear }) => {
  return (
    <View style={styles.wrapper} id={'portfolio'}>
      <View style={styles.contentWrapper}>
        <PortfolioCards.KiteWeb onSelect={() => setSelected('kite-web')}/>
        <PortfolioCards.BeconWeb onSelect={() => setSelected('becon-web')}/>
        <PortfolioCards.AWSCloudroom onSelect={() => setSelected('cloudroom-web')}/>
        <PortfolioCards.AWSGirlsTechSeries onSelect={() => setSelected('aws-girls-tech-series-web')}/>
        <PortfolioCards.GoQuestWeb onSelect={() => setSelected('go-quest-web')}/>
        <View down={'mid'}>{MobileComponents.renderMobileModals({ selected, clear })}</View>
      </View>
    </View>
  )
}

export default WebShowcase
