/* eslint-disable no-console */
import { Page, React, onMount, onUpdate } from 'lib'
import { Landing } from '../pages/_homepage/_landing'
import Portfolio from '../pages/_homepage/_portfolio/_index'
import Services from '../pages/_homepage/_services/_index'
import Pricing from '../pages/_homepage/_pricing/_index'
import FAQ from '../pages/_homepage/_faq/_index'
import ContactUs from '../pages/_homepage/_contactUs'
import PartnerCompanies from '../pages/_homepage/_partnersCompanies'
import BlogSlider from '../pages/_homepage/_blogSlider'
import ClientsTestimonials from '../pages/_homepage/_testimonials'
import WhoWeAre from '../pages/_homepage/_whoWeAre'
import Team from '../pages/_homepage/_team'
import { AppStatus } from 'actions'
import { graphql, useStaticQuery } from 'gatsby'
import ServicesInfo from './ServicesInfo'
import { Theme } from '_app'
import { useLocation } from 'react-use'
import animateScrollTo from 'animated-scroll-to'
import DefaultHomepageData from 'pages/_homepage/_data'

const defaultScrollOptions = {
  cancelOnUserAction: true,
  easing: (t) => --t * t * t + 1, // easeOutCubic
  // easing: (t) => { return 1 + (--t) * t * t * t * t }, // easeOutQuint
  elementToScroll: null, // DOM element that should be scrolled
  horizontalOffset: 0,
  maxDuration: 12000,
  minDuration: 2500,
  speed: 800, // Duration of the scroll per 1000px
  verticalOffset: 0,
}

const instaScrollOptions = {
  maxDuration: 0,
  minDuration: 0,
  speed: 0,
}

export const ServicesEnum = {
  AI: 'ai',
  NextGen: 'next-gen',
  web: 'web',
  apps: 'mobile',
}


export const BaseHomepage = (props) => {

  const data = props.data ? { ...props.data } : DefaultHomepageData

  const location = useLocation()

  const { height } = Theme.hooks.size()
  const isMobile = Theme.hooks.down('mid')

  onMount(() => {
    AppStatus.setActivity(null)
    defaultScrollOptions.elementToScroll = window
    // HACK dumb hack because otherwise anchor scroll from other pages doesn't work -.-
  })

  onUpdate(() => {
    if (location.trigger != 'load') {
      scrollToHash()
    }
  }, [location])

  const scrollToHash = (hash = location.hash, options = {}) => {
    const lastChar = location.href.charAt(location.href.length - 1)
    const isHome = location.href.slice(0, -1) == location.origin
    const shouldScrollTop =
      isHome || lastChar == '#' || hash == '#top' || hash == '#home'
    const animationDistanceThreshold = height * 2.5
    if (shouldScrollTop) {
      if (window.scrollY > animationDistanceThreshold) {
        animateScrollTo(0, { ...defaultScrollOptions, ...instaScrollOptions })
      } else {
        animateScrollTo(0, { ...defaultScrollOptions })
      }
    } else if (hash) {
      if (hash == '#portfolio') {
        const [scrollToPos] = getScrollParams(hash, {}, props?.service === ServicesEnum.web || props?.service === ServicesEnum.apps ? 600 : 0)
        // not really sure why this 600 is needed but it is a quick fix :D
        animateScrollTo(scrollToPos, {
          ...defaultScrollOptions,
          ...options,
        })
      } else if (hash == '#servicesInfo') {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile })
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else if (hash == '#partnerCompanies') {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile })
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else if (hash == '#services') {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile })
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else if (hash == '#pricing' && props?.service === ServicesEnum.web || props?.service === ServicesEnum.apps) {
        const [scrollToPos] = getScrollParams(hash, { center: !isMobile }, 180)
        animateScrollTo(scrollToPos, { ...defaultScrollOptions, ...options })
      } else {
        const [scrollToPos, distance] = getScrollParams(hash)
        if (distance < animationDistanceThreshold) {
          options = { ...options, ...defaultScrollOptions }
        }
        if (scrollToPos) {
          const offset = hash === '#contact' ? -50 : 0
          // just a little adjust to center better the form when clicking contact in header nav bar
          animateScrollTo(scrollToPos + offset, {
            ...defaultScrollOptions,
            ...instaScrollOptions,
            ...options,
          })
        }
      }
    }
  }

  function getScrollParams(hash, options, offset = 0) {
    const element = document.getElementById(hash.substring(1))
    let scrollToPos = null
    let distance = null
    try {
      const rect = element.getBoundingClientRect()
      const topDistance =
        rect.top -
        (options?.headerMargin ? Theme.values.maxHeaderMenuHeight : 0)
      const viewportCenterDistance =
        (rect.top + rect.bottom) / 2 -
        height / 2 -
        (options?.headerMargin ? Theme.values.maxHeaderMenuHeight : 0)
      distance = options?.center ? viewportCenterDistance : topDistance
      scrollToPos = distance + window.scrollY + offset
    } catch (err) {
      warn({ err, location })
    }
    return [scrollToPos, distance]
  }

  const queryData = useStaticQuery(query)
  const blogPostsPreview = queryData.allMarkdownRemark.edges

  deb.perf('render Homepage', { blogPostsPreview, location })

  const AppsHomepage = () => {
    const service = ServicesEnum.apps
    return (
      <Page showChat fullWidth service={service}>
        <Landing data={data?.landing} service={service}/>
        <ServicesInfo data={data?.servicesInfo}/>
        <Pricing data={data?.pricing}/>
        <FAQ data={data?.faq}/>
        <Portfolio service={service} data={data?.portfolio}/>
        <ClientsTestimonials/>
        <WhoWeAre/>
        <Team/>
        <BlogSlider data={blogPostsPreview}/>
        <ContactUs/>
      </Page>
    )
  }


  const WebHomepage = () => {
    const service = ServicesEnum.web
    return (
      <Page showChat fullWidth service={service}>
        <Landing data={data?.landing} service={service}/>
        <ServicesInfo data={data?.servicesInfo}/>
        <Pricing data={data?.pricing}/>
        <FAQ data={data?.faq}/>
        <Portfolio service={service} data={data?.portfolio}/>
        <ClientsTestimonials/>
        <WhoWeAre/>
        <Team/>
        <BlogSlider data={blogPostsPreview}/>
        <ContactUs/>
      </Page>
    )
  }

  const AIHomepage = () => {
    const service = ServicesEnum.AI
    return (
      <Page showChat fullWidth service={service}>
        <Landing data={data?.landing} service={service}/>
        <PartnerCompanies/>
        <ServicesInfo data={data?.servicesInfo}/>
        <Portfolio service={service} data={data?.portfolio}/>
        <FAQ data={data?.faq}/>
        <Pricing data={data?.pricing}/>
        <ClientsTestimonials/>
        <Team/>
        <BlogSlider data={blogPostsPreview}/>
        <ContactUs/>
      </Page>
    )
  }

  const NextGenHomepage = () => {
    const service = ServicesEnum.NextGen
    return (
      <Page showChat fullWidth service={service}>
        <Landing data={data?.landing} service={service}/>
        <PartnerCompanies/>
        <ServicesInfo data={data?.servicesInfo}/>
        <Portfolio service={service} data={data?.portfolio}/>
        <FAQ data={data?.faq}/>
        <Pricing data={data?.pricing}/>
        <ClientsTestimonials/>
        <Team/>
        <BlogSlider data={blogPostsPreview}/>
        <ContactUs/>
      </Page>
    )
  }

  const DefaultHomepage = () => {
    return (
      <Page showChat fullWidth isHomepage>
        <Landing data={data?.landing}/>
        <PartnerCompanies/>
        <Services/>
        <Portfolio service={'default'} data={data?.portfolio}/>
        <ClientsTestimonials/>
        <FAQ data={data?.faq}/>
        <Pricing data={data?.pricing} service={'default'}/>
        <Team/>
        <BlogSlider data={blogPostsPreview}/>
        <ContactUs/>
      </Page>
    )
  }

  switch (props?.service) {
    case ServicesEnum.apps:
      return <AppsHomepage/>
    case ServicesEnum.web:
      return <WebHomepage/>
    case ServicesEnum.NextGen:
      return <NextGenHomepage/>
    case ServicesEnum.AI:
      return <AIHomepage/>
    default:
      return <DefaultHomepage/>
  }

}

const query = graphql`
  query {
    allMarkdownRemark(sort: { frontmatter: { date: DESC } }) {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 400)
          frontmatter {
            title
            subtitle
            date(formatString: "DD MMMM, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 1800, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            author
          }
          fields {
            slug
          }
          timeToRead
        }
      }
    }
  }
`

export default BaseHomepage
