import { Theme } from 'app'

let styles = {
  //
  // Display types
  //
  inline: {
    ...Theme.inline,
  },
  flex: {
    ...Theme.flex,
  },
  absolute: {
    ...Theme.absolute,
  },
  inlineFlex: {
    ...Theme.inlineFlex,
  },
  underline: {
    textDecoration: 'underline',
    textDecorationLine: 'underline',
  },
  //
  // Layout
  //
  row: {
    ...Theme.row,
  },
  column: {
    ...Theme.column,
  },
  full: {
    ...Theme.full,
  },
  fullWidth: {
    ...Theme.fullWidth,
    width: '100%',
  },
  fullHeight: {
    ...Theme.fullHeight,
  },
  fullView: {
    ...Theme.fullView,
  },
  fullViewWidth: {
    ...Theme.fullViewWidth,
  },
  fullViewHeight: {
    ...Theme.fullViewHeight,
  },
  textRight: {
    ...Theme.textRight,
  },
  textLeft: {
    ...Theme.textLeft,
  },
  textCenter: {
    ...Theme.textCenter,
  },
  whole: {
    ...Theme.whole,
  },
  center: {
    ...Theme.center,
  },
  alignStart: {
    ...Theme.alignStart,
  },
  alignEnd: {
    ...Theme.alignEnd,
  },
  alignCenter: {
    ...Theme.alignCenter,
  },
  justifyStart: {
    ...Theme.justifyStart,
  },
  justifyEnd: {
    ...Theme.justifyEnd,
  },
  justifyCenter: {
    ...Theme.justifyCenter,
  },
  justifySpaceBetween: {
    ...Theme.justifySpaceBetween,
  },
  blur: {
    ...Theme.blur,
  },
  listStyles: {
    ...Theme.listStyles,
  },
  hidden: {
    ...Theme.hidden,
  },
  //
  // Other
  //
  debug: {
    ...Theme.debug('rgba(255, 0, 0, 1)'),
  },
  debugOrange: {
    ...Theme.debug('rgba(255, 200, 0, 1)'),
  },
  debugGreen: {
    ...Theme.debug('rgba(100, 200, 0, 1)'),
  },
  darkest: {
    backgroundColor: Theme.colors.darkest,
  },
}

initStyles()

function initStyles() {
  const sizes = 20
  const temp = {}
  function makeVariant(name, main, types, units = '', multiplier = 1) {
    for (let i = 1; i <= sizes; i++) {
      const key = `${name}:${i}`
      for (const t in types) {
        const val = Theme.spacing(i * multiplier)
        temp[key] = {
          ...temp[key],
          [`${main}${types[t]}`]: units ? `${val}${units}` : val,
        }
      }
    }
  }

  function addVariant(rootName) {
    makeVariant(`${rootName}`, `${rootName}`, [
      `Top`,
      `Bottom`,
      `Left`,
      `Right`,
    ])
    makeVariant(`${rootName}Top`, `${rootName}`, [`Top`])
    makeVariant(`${rootName}Bottom`, `${rootName}`, [`Bottom`])
    makeVariant(`${rootName}Left`, `${rootName}`, [`Left`])
    makeVariant(`${rootName}Right`, `${rootName}`, [`Right`])
    makeVariant(`${rootName}Vertical`, `${rootName}`, [`Top`, `Bottom`])
    makeVariant(`${rootName}Horizontal`, `${rootName}`, [`Left`, `Right`])
  }

  addVariant('padding')
  addVariant('margin')
  makeVariant(`maxWidth`, `max`, ['Width'], 'px', 20)
  makeVariant(`minWidth`, `min`, ['Width'], 'px', 20)

  styles = { ...styles, ...temp }
}

export default styles
