import { React, View, Text, Image } from 'lib'
import { Theme } from 'app'

const Card = ({ item, small, selected, cardBGColor, selectedCardBGColor }) => {

  const { title, description, app, logo } = item

  return (
    <>
      <View style={[styles.card, small && { backgroundColor: selected ? selectedCardBGColor : cardBGColor }]}>
        <View variant={'row alignCenter marginBottom:2'}>
          <Image src={logo} style={styles.logo} alt={`${title} logo`}/>
          <View variant={'marginLeft:2'}>
            <Text variant={`p1 bold `} text={app}/>
            <Text variant={`p2 charcoalGrey`} style={styles.title} text={title}/>
          </View>
        </View>
        <Text variant={`p1 charcoalGrey`} text={description}/>
      </View>
    </>
  )

}

const LOGO_SIZE = 40

export default Card

const styles = {
  card: {
    minHeight: 320,
    width: 370,
    padding: Theme.spacing(5),
    borderRadius: Theme.values.borderRadius.medium,
    [Theme.media.down('xxlarge')]: {
      padding: Theme.spacing(4),
      minHeight: 270,
    },
    [Theme.media.down('large')]: {
      padding: Theme.spacing(3),
    },
    [Theme.media.down('small')]: {
      boxSizing: 'border-box',
      marginBottom: Theme.spacing(4),
      width: '95%',
      marginLeft: Theme.spacing(1),
      minHeight: 350,
    },
    [Theme.media.down('tiny')]: {
      minHeight: 400,
    },
  },
  logo: {
    width: LOGO_SIZE,
    height: LOGO_SIZE,
    borderRadius: Theme.values.borderRadius.round,
  },
  title: {
    fontWeigth: '400',
  },
}
