import { View, Text, CenterWrapper, React, equals, useState, Touchable, AnalyticsView } from 'lib'
import { Theme } from 'app'
import { FAQItem } from 'components'


function FAQ(props) {

  deb.perf('render Faq')

  const [expand, setExpand] = useState(false)

  const unexpandedLimit = 5

  const showData = expand ? props?.data : props?.data?.slice?.(0, unexpandedLimit)
  const buttonText = expand ? 'Show less' : 'Show more'
  const shouldShowExpandDataOption = props?.data?.length > unexpandedLimit

  const onPress = () => {
    setExpand(!expand)
  }

  return (
    <View style={styles.wrapper} id={'faq'}>
      <CenterWrapper contentContainerStyle={styles.centerWrapper}>
        <View style={styles.leftSection}>
          <AnalyticsView gaLabel={'FAQ'} gaAction={'Section header'} style={styles.leftSection}>
            <Text variant={'h2'}>Frequently<br/>asked<br/>questions</Text>
          </AnalyticsView>
        </View>
        <View style={styles.accordionWrapper}>
          {showData?.map?.((item, index) => {
            return (
              <FAQItem
                key={index}
                index={index}
                title={item.title}
                content={item.content}
              />
            )
          })}
          {shouldShowExpandDataOption ? (
            <Touchable style={styles.touchable} onPress={onPress} gaLabel={'FAQ'} gaAction={`${buttonText}`}>
              <Text variant={'p1 link'}>{buttonText}</Text>
            </Touchable>
          ) : null}
        </View>
      </CenterWrapper>
    </View>
  )
}


function areEqual(prevProps, nextProps) {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(FAQ, areEqual)


const styles = {
  wrapper: {
    backgroundColor: 'white',
    minHeight: '50vh',
    paddingTop: Theme.spacing(12),
    paddingBottom: Theme.spacing(12),
    [Theme.media.down('mid')]: {
      paddingTop: Theme.spacing(8),
      paddingBottom: Theme.spacing(8),
    },
  },
  centerWrapper: {
    justifyContent: 'space-between',
    display: 'flex',
    [Theme.media.down('small')]: {
      flexDirection: 'column',
    },
  },
  leftSection: {
    flex: 1,
    maxWidth: 450,
    [Theme.media.down('large')]: {
      marginRight: Theme.spacing(8),
    },
    [Theme.media.down('mid')]: {
      marginRight: 0,
      marginBottom: Theme.spacing(8),
      ...Theme.center,
      ...Theme.flex,
      textAlign: 'center',
    },
  },
  accordionWrapper: {
    flex: 1,
    maxWidth: Theme.values.maxTextContentWidth,
    flexDirection: 'column',
  },
  touchable: {
    marginTop: Theme.spacing(2),
    marginLeft: Theme.spacing(8),
  },
}
