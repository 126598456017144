import { Theme } from '_app'
import { CenterWrapper, React, View, Text, Image, useState, Touchable } from 'lib'
import { data } from './_data'
import { Carousel } from 'components'

const Team = () => {

  const small = Theme.hooks.down('small')
  const { padding } = Tools.getMaxContentWidth()

  const renderItem = ({ item }) => {

    const [hover, setHover] = useState(false)

    if (item.placeholder) {
      return (
        <>
          <View style={[styles.cardWrapper, styles.roleWrapper]}>
            <Text style={styles.placeholderText} text={item?.placeholder}/>
          </View>
          <Touchable variant={'column'} to={item.link} openNewTab>
            <Text variant={`p1 white bold marginTop:3`} text={item.name}/>
            <Text variant={`p1 white marginTop:1`} text={item.role}/>
          </Touchable>
        </>
      )
    }

    return (
      <View style={styles.cardWrapper} onHover={setHover}>
        <View>
          <Image source={item.img_a} style={styles.image} alt={'team member photo'}/>
          <Text variant={`p1 white bold marginTop:3`} text={item.name}/>
          <Text variant={`p1 white marginTop:1`} text={item.role}/>
        </View>
        <View style={[styles.altImageWrapper, hover && styles.altImageWrapperHover]}>
          <Image source={item.img_b} style={styles.image} alt={'team member photo'}/>
        </View>
      </View>
    )
  }


  return (
    <View style={styles.wrapper}>

      <CenterWrapper contentContainerStyle={[styles.textWrapper, { paddingLeft: small && padding }]} centerContent={!small}>
        <Text variant={`h5 semiBold uppercase primary marginBottom:3`} text={`Our Team`}/>
        <Text variant={`h3 white`} style={styles.description} text={`Say hello! We are innovators. Techies. Computer geeks. Design perfectionists. World Changers.`}/>
      </CenterWrapper>

      <Carousel data={data} renderItem={renderItem}/>

    </View>
  )
}

const DESKTOP_IMAGE_SIZE = 350
const TABLET_IMAGE_SIZE = 350
const MOBILE_IMAGE_SIZE = 250

const styles = {
  wrapper: {
    paddingTop: Theme.spacing(20),
    paddingBottom: Theme.spacing(20),
    minHeight: 700,
    position: 'relative',
    [Theme.media.down('mid')]: {
      paddingTop: Theme.spacing(10),
      paddingBottom: Theme.spacing(10),
    },
    [Theme.media.down('small')]: {
      minHeight: 600,
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [Theme.media.down('small')]: {
      maxWidth: '60%',
    },
  },
  description: {
    marginBottom: Theme.spacing(10),
    [Theme.media.down('small')]: {
      marginBottom: Theme.spacing(3),
    },
  },
  image: {
    objectFit: 'cover',
    width: DESKTOP_IMAGE_SIZE,
    height: DESKTOP_IMAGE_SIZE,
    borderRadius: Theme.values.borderRadius.smallish,
    [Theme.media.down('largeish')]: {
      width: TABLET_IMAGE_SIZE,
      height: TABLET_IMAGE_SIZE,
    },
    [Theme.media.down('small')]: {
      width: MOBILE_IMAGE_SIZE,
      height: MOBILE_IMAGE_SIZE,
    },
    [Theme.media.down('tiny')]: {
      width: MOBILE_IMAGE_SIZE * 0.6,
      height: MOBILE_IMAGE_SIZE * 0.6,
    },
  },
  cardWrapper: {
    position: 'relative',
  },
  altImageWrapper: {
    opacity: 0,
    transition: '500ms',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  altImageWrapperHover: {
    opacity: 1,
    transition: '300ms',
  },
  roleWrapper: {
    ...Theme.center,
    ...Theme.flex,
    textAlign: 'center',
    background: Theme.colors.primary,
    width: DESKTOP_IMAGE_SIZE,
    height: DESKTOP_IMAGE_SIZE,
    borderRadius: Theme.values.borderRadius.smallish,
    [Theme.media.down('largeish')]: {
      width: TABLET_IMAGE_SIZE,
      height: TABLET_IMAGE_SIZE,
    },
    [Theme.media.down('small')]: {
      width: MOBILE_IMAGE_SIZE,
      height: MOBILE_IMAGE_SIZE,
    },
    [Theme.media.down('tiny')]: {
      width: MOBILE_IMAGE_SIZE * 0.6,
      height: MOBILE_IMAGE_SIZE * 0.6,
    },
  },
  placeholderText: {
    color: 'white',
    fontSize: 45,
  },
}

export default Team
