const BASE_URL = `assets/team`

export const data = [
  {
    name: `Lucas`,
    img_a: `${BASE_URL}/lucas_a.jpg`,
    img_b: `${BASE_URL}/lucas_b.jpg`,
    role: `Backend Engineer`,
  },
  {
    name: `Evair`,
    img_a: `${BASE_URL}/evair_a.jpg`,
    img_b: `${BASE_URL}/evair_b.jpg`,
    role: `Data Scientist`,
  },
  {
    name: `Victor`,
    img_a: `${BASE_URL}/victor_a.png`,
    img_b: `${BASE_URL}/victor_b.png`,
    role: `Co-Founder & CTO`,
  },
  {
    name: `Vivian`,
    img_a: `${BASE_URL}/vivian_a.png`,
    img_b: `${BASE_URL}/vivian_b.png`,
    role: `Co-Founder & COO`,
  },
  {
    name: `Leonardo`,
    img_a: `${BASE_URL}/leo_a.jpg`,
    img_b: `${BASE_URL}/leo_b.jpg`,
    role: `Frontend Engineer `,
  },
  {
    name: `Matt`,
    img_a: `${BASE_URL}/matheus_a.jpg`,
    img_b: `${BASE_URL}/matheus_b.jpg`,
    role: `Project Manager`,
  },
  {
    name: `Yumi`,
    img_a: `${BASE_URL}/yumi_a.jpg`,
    img_b: `${BASE_URL}/yumi_b.jpg`,
    role: `Head of Design`,
  },
  {
    name: `Kleber`,
    img_a: `${BASE_URL}/kleber_a.jpg`,
    img_b: `${BASE_URL}/kleber_b.jpg`,
    role: `Frontend Engineer`,
  },
  {
    name: `Kenzo`,
    img_a: `${BASE_URL}/kenzo_a.jpg`,
    img_b: `${BASE_URL}/kenzo_b.jpg`,
    role: `Frontend Engineer`,
  },
  {
    name: `Sarah`,
    img_a: `${BASE_URL}/sarah_a.jpg`,
    img_b: `${BASE_URL}/sarah_b.jpg`,
    role: `UI/UX Designer`,
  },
  {
    name: `Vinny`,
    img_a: `${BASE_URL}/vini_a.jpg`,
    img_b: `${BASE_URL}/vini_b.jpg`,
    role: `Project Manager`,
  },
  {
    name: `Paulo`,
    img_a: `${BASE_URL}/paulo_a.jpg`,
    img_b: `${BASE_URL}/paulo_b.jpg`,
    role: `Frontend Engineer`,
  },
  {
    name: `Fernando`,
    img_a: `${BASE_URL}/fernando_a.jpg`,
    img_b: `${BASE_URL}/fernando_b.jpg`,
    role: `Frontend Engineer`,
  },
  {
    name: `Gabriel`,
    img_a: `${BASE_URL}/gabriel_a.jpg`,
    img_b: `${BASE_URL}/gabriel_b.jpg`,
    role: `Frontend Engineer`,
  },
  {
    name: `Felipe`,
    img_a: `${BASE_URL}/luiz_felipe_a.jpg`,
    img_b: `${BASE_URL}/luiz_felipe_b.jpg`,
    role: `Frontend Engineer `,
  },
  {
    name: `Luana`,
    img_a: `${BASE_URL}/luana_a.jpg`,
    img_b: `${BASE_URL}/luana_b.jpg`,
    role: `UI/UX Designer`,
  },
  {
    name: `Garcez`,
    img_a: `${BASE_URL}/garcez_a.jpg`,
    img_b: `${BASE_URL}/garcez_b.jpg`,
    role: `Frontend Engineer`,
  },
  {
    name: `Danilo`,
    img_a: `${BASE_URL}/danilo_a.jpg`,
    img_b: `${BASE_URL}/danilo_b.jpg`,
    role: `Frontend Engineer`,
  },
  {
    name: `Filipe`,
    img_a: `${BASE_URL}/filipe_a.jpg`,
    img_b: `${BASE_URL}/filipe_b.jpg`,
    role: `Frontend Engineer`,
  },
  {
    name: `Takuya`,
    img_a: `${BASE_URL}/takuya_a.jpg`,
    img_b: `${BASE_URL}/takuya_b.jpg`,
    role: `Frontend Engineer`,
  },
  {
    name: `Gini`,
    img_a: `${BASE_URL}/gini_a.jpg`,
    img_b: `${BASE_URL}/gini_b.jpg`,
    role: `Talent Manager`,
  },
  {
    name: `José`,
    img_a: `${BASE_URL}/jose_a.jpg`,
    img_b: `${BASE_URL}/jose_b.jpg`,
    role: `QA Tester`,
  },
  {
    name: `Join us!`,
    email: `apply@codeleap.co.uk`,
    link: `/careers`,
    role: `See our careers page`,
    placeholder: `?`,
  },
]

