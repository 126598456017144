import AutoImagingCard from './_autoimaging'
import AWSCloudroomCard from './_aws-cloudroom'
import AWSGirlsTechSeriesCard from './_aws-girls-tech-series'
import BearableCard from './_bearable'
import BeconMobileCard from './_becon-mobile'
import BeconWebCard from './_becon-web'
import CherryMobileCard from './_cherry-mobile'
import GoQuestMobileCard from './_go-quest-mobile'
import GoQuestWebCard from './_go-quest-web'
import KiteGeneralCard from './_kite-general'
import KiteMobileCard from './_kite-mobile'
import KiteWebCard from './_kite-web'
import SkamperMobileCard from './_skamper-mobile'

const PortfolioCards = {
  Bearable: BearableCard,
  AWSCloudroom: AWSCloudroomCard,
  AWSGirlsTechSeries: AWSGirlsTechSeriesCard,
  BeconMobile: BeconMobileCard,
  BeconWeb: BeconWebCard,
  CherryMobile: CherryMobileCard,
  GoQuestMobile: GoQuestMobileCard,
  GoQuestWeb: GoQuestWebCard,
  KiteMobile: KiteMobileCard,
  KiteWeb: KiteWebCard,
  KiteGeneral: KiteGeneralCard,
  SkamperMobile: SkamperMobileCard,
  AutoImaging: AutoImagingCard,
}

export default PortfolioCards
