import { React, View } from 'lib'
import { Theme } from 'app'
import { Reveal, Fade } from 'components'


function BackgroundCircle(props) {
  const { contentHeight, short } = props
  const wrapperStyles = { minHeight: contentHeight*0.95 }
  const tweenProps = {
    from: {
      css: {
        position: 'relative',
        transform: `translate3d(0, 0, 0)`,
      },
    },
    to: {
      css: {
        position: 'relative',
        transform: `translate3d(0, ${contentHeight*0.75}px, 0)`,
      },
    },
    ease: 'none',
  }

  return (
    <View style={[styles.wrapper, wrapperStyles]}>
      <Reveal tweenProps={tweenProps} variant={'parallaxBackground'} offset={`${-circleSize/2}vw`} duration={contentHeight*0.95}>
        <div>
          <Fade variant={'scale'} delay={0} ready={true}>
            <View style={[styles.circle, short && styles.circleShort]}></View>
          </Fade>
        </div>
      </Reveal>
    </View>
  )
}

const circleSize = 400 // vw

const styles = {
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: '100vh',
    width: '100vw',
    overflowY: 'hidden',
    overflowX: 'visible',
    zIndex: -1,
  },
  circle: {
    position: 'absolute',
    alignSelf: 'center',
    background: 'linear-gradient(90deg, #8481FA, #8481FA, #8481FA, #38C9C8, #38C9C8, #38C9C8)',
    left: `-${(circleSize-100)/2}vw`,
    right: `${(circleSize-100)/2}vw`,
    width: `${circleSize}vw`,
    height: `${circleSize}vw`,
    top: `-${circleSize*0.95}vw`,
    [Theme.media.up('xxlarge')]: {
      top: `-${circleSize*0.97}vw`,
    },
    borderRadius: Theme.values.borderRadius.round,
  },
  circleShort: {
    top: `-${circleSize*1.02}vw`,
    [Theme.media.up('xxlarge')]: {
      top: `-${circleSize*0.99}vw`,
    },
    [Theme.media.up('huge')]: {
      top: `-${circleSize*0.995}vw`,
    },
  },
}

export default BackgroundCircle
