/* eslint-disable no-console */
import AppsShowcase from './_showcases/apps'
import NextGenShowcase from './_showcases/next-gen'
import AIShowcase from './_showcases/ai'
import DefaultShowcase from './_showcases/default'
import {
  React,
  equals,
  onMount,
  useState,
} from 'lib'
import { Theme } from 'app'
import WebShowcase from './_showcases/web'
import { ServicesEnum } from 'components/BaseHomepage'

export const Portfolio = (props) => {

  const isMobile = Theme.hooks.down('mid')

  const [scrollEventListener, setScrollEventListener] = useState(false)
  const [selected, setSelected] = useState(null)
  const clear = () => setSelected(null)

  const { height } = Theme.hooks.size()

  onMount(() => {
    setUpScrollListener()
  })

  const setUpScrollListener = () => {
    if (isMobile && !scrollEventListener) {
      document.addEventListener('scroll', function () {
        setSelected(null)
        Tools.throttle(() => setSelected(null), 1000)
      })
      setScrollEventListener(true)
    }
  }


  const pageProps = {
    selected,
    setSelected,
    clear,
    isMobile,
    height,
    ...props,
  }

  deb.perf('render Portfolio', {
    props,
    selected,
    scrollEventListener,
  })


  switch (props?.service) {
    case ServicesEnum.apps:
      return <AppsShowcase {...pageProps}/>
    case ServicesEnum.web:
      return <WebShowcase {...pageProps}/>
    case ServicesEnum.NextGen:
      return <NextGenShowcase {...pageProps}/>
    case ServicesEnum.AI:
      return <AIShowcase {...pageProps}/>
    default:
      return <DefaultShowcase {...pageProps}/>
  }

}

function areEqual(prevProps, nextProps) {
  const res = equals(prevProps, nextProps)
  return res
}

export default React.memo(Portfolio, areEqual)
