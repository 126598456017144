// eslint-disable-next-line no-restricted-imports
import React from 'react'
import CardComponents from '../_components/_card'
import DemoDevice from '../_components/_demoDevice'
import { Image, View } from 'lib'
import { Theme } from '_app'
import { Reveal } from 'components'
import { apps } from './_data'

const KiteGeneralCard = ({ onSelect, position = 'right' }) => {

  const { height } = Theme.hooks.size()

  const isLargeish = Theme.hooks.down('largeish')
  const upxxLarge = Theme.hooks.up('xxlarge')

  const data = apps['kite-general']

  return (
    <CardComponents.AppRow
      elemName={'kite'}
      background={data.background}
      position={position}
    >
      <View style={styles.wrapper}>
        <CardComponents.AppDescription data={data} position={position}/>
        {isLargeish ? (
          <DemoDevice.DemoRow data={data} onSelect={onSelect}/>
        ) : (
          <>
            <Reveal
              variant={'parallaxReverse'}
              amount={'22vh'}
              offset={height * -(upxxLarge ? 0.3 : 0.1)}
              duration={height * 1.1}
              stopProgressAt={upxxLarge ? 0.5 : 0.5}
            >
              <div className={'willChangeTransform'}>
                <View style={styles.tabletWrapper}>
                  <Image
                    loading={'eager'}
                    src={'assets/portfolio/kite/web/background-tablet.png'}
                    style={styles.image}
                  />
                </View>
              </div>
            </Reveal>
            <Reveal
              variant={'parallaxBackground'}
              amount={'10vh'}
              offset={height * -(upxxLarge ? 0.75 : 0.1)}
              duration={height * 1.5}
              disabled={isLargeish}
              stopProgressAt={upxxLarge ? 0.8 : 0.25}
            >
              <div className={'willChangeTransform'}>
                <DemoDevice.Demo
                  alt={`${data.title} demo 2`}
                  image={'assets/portfolio/kite/mobile/kite1.png'}
                  style={styles.device}
                  scale={0.5}
                />
              </div>
            </Reveal>
          </>

        )}
      </View>
    </CardComponents.AppRow>
  )

}

const styles = {
  wrapper: {
    [Theme.media.up('mid')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  tabletWrapper: {
    width: `calc(0.4 * min(95vw, 1500px))`,
    height: 'calc(0.6 * max(520px, min(75vh, 900px)))',
    border: `10px solid ${Theme.colors.white}`,
    borderRadius: Theme.values.borderRadius.large,
    backgroundColor: Theme.colors.white,
    [Theme.media.down('xlarge')]: {
      width: `calc(0.5 * min(95vw, 1500px))`,
    },
    [Theme.media.down('largeish')]: {
      height: 'calc(0.55 * max(520px, min(75vh, 900px)))',
    },
  },
  device: {
    position: 'absolute',
    left: -200,
    [Theme.media.down('largeish')]: {
      left: -220,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: Theme.values.borderRadius.medium,
    objectFit: 'fill',
  },
}

export default KiteGeneralCard
